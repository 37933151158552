/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';

import Container from 'src/kit/Container/Container';
import {
  benefitsTitleCSS,
  benefitsWrapperCSS,
  cardTitleCSS,
  detailsBlockCSS,
  heroSectionCSS,
  mobileBenefitsWrapperCSS,
  offerDetailsCSS,
  savingsCardCSS,
  BBBIconCSS,
  savingsValueSuffixCSS,
  hero2WrapperCSS,
  savingsValue2CSS,
  savingsValueWrapper2CSS,
  premiumsWrapperCSS
} from './BundleMsgHero.style';
import { getPercentRangeValue } from 'src/utils/percentageCalculation';
import SavingsOffer from 'src/components/SavingsHero/SavingsOffer/SavingsOffer';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { DefaultLocation, Flows } from 'src/interfaces/IPage';
import { Card } from 'src/shared/components/Card/Card';
import useResponsive from 'src/hooks/useResponsive';
import { useParams } from 'react-router-dom';
import { SavingsHeroProps } from 'src/components/SavingsHero/SavingsHero.types';
import noop from 'src/utils/noop';
import QuoteBenefits from 'src/components/QuoteBenefits/QuoteBenefits';
import { getSavingsBenefitsList } from 'src/components/SavingsHero/SavingHero.utils';
import { SavingsQuote } from 'src/interfaces/IQuotes';
import useConfig from 'src/api/config/useConfig';
import { getServicerNameToDisplay } from 'src/constants/servicers';
import { QuoteBenefitsCustomCSSProps } from 'src/components/QuoteBenefits/QuoteBenefits.types';
import useDisclosures from 'src/api/disclosures/useDisclosures';
import BBBIcon from 'src/components/BBBIcon/BBBIcon';
import { BBBIconVariation } from 'src/components/BBBIcon/BBBIcon.types';

import StyledDashedRow from 'src/components/HistoricalPremium/StyledDashedRow';
import answersService from 'src/api/answers/answers.service';
import BundleCheckBox from 'src/components/BundleMsgComponents/BundleCheckBox/BundleCheckBox';
import BundleHeroMessage from 'src/components/BundleMsgComponents/BundleHeroMessage/BundleHeroMessage';
import Format from 'src/utils/format';
import { heroWrapperCSS } from 'src/components/SavingsHero/SavingsOffer/SavingsOffer.style';
import { PolicyInfoBundleHeader } from 'src/components/BundleMsgComponents/PolicyInfoBundleHeader/PolicyInfoBundleHeader';
import { SimpleBundleHeader } from 'src/components/BundleMsgComponents/SimpleBundleHeader/SimpleBundleHeader';

const BENEFITS_TITLE = 'Benefits of shopping with Matic.';

const BundleMsgHero: React.FC<SavingsHeroProps> = ({
  offer,
  onSubmit = noop,
  isSubmitLoading,
  preScoreVariation = false,
  isImplicitDisclosuresAccepted,
  isSecondTestVariation = false,
  userHasBundlePreference = false
}) => {
  const [activeQuote] = useState<SavingsQuote>(offer?.quotes[0]);
  const [isToggleOn, setIsToggleOn] = useState(userHasBundlePreference);

  const { gid, flow } = useParams() as DefaultLocation;
  const { isMobile, isDesktop } = useResponsive();
  const { data: config } = useConfig(gid);
  const { data: disclosuresData } = useDisclosures(gid);
  const [isTooltipOn, setIsTooltipOn] = useState(false);

  const { servicer_key = '', servicer_name } = config || {};
  const servicerName = getServicerNameToDisplay(servicer_key, servicer_name);

  const onFormSubmit = async (phone: string, selectedFromModal: boolean) => {
    if (selectedFromModal) {
      analytics.track(SEGMENT.TERMS_OF_SERVICE_ACCEPTED_FROM_MODAL, gid, flow);
    }

    disclosuresData?.disclosures?.forEach(disclosure => {
      analytics.track(SEGMENT.DISCLOSURE_ACCEPTED, gid, flow, {
        disclosure_gid: disclosure.gid,
        page: SEGMENT.PAGES_KEY.SAVINGS
      });
    });

    analytics.track(SEGMENT.QUOTE_SELECTED, gid, Flows.Savings, {
      quote_type: SEGMENT.SAVINGS_PAGE_DEFAULT_QUOTE_TYPE,
      savings_amount: activeQuote.saving_value,
      carrier_key: activeQuote.carrier.key.toLowerCase(),
      ordinal: 1,
      bundle_status: null,
      quote_gid: activeQuote.gid,
      ...(activeQuote.premium.value && { premium_value: activeQuote.premium.value })
    });

    analytics.track(SEGMENT.SAVINGS_BUNDLE.POSITION_SUBMITTED, gid, flow, {
      toggle_state: isToggleOn ? 'on' : 'off'
    });

    onSubmit(activeQuote, phone);
  };

  const changeToggleState = () => {
    setIsToggleOn(prevState => {
      const isToggleOn = !prevState;
      const customerBundleChoice = isToggleOn ? 'yes' : 'no';

      answersService.updateAnswers(gid, customerBundleChoice);
      analytics.track(SEGMENT.SAVINGS_BUNDLE.TOGGLED_CLICKED, gid, flow, {
        toggle_state: isToggleOn ? 'on' : 'off'
      });
      return isToggleOn;
    });
  };

  const geQuotesBenefit = (customCSS?: QuoteBenefitsCustomCSSProps) => (
    <QuoteBenefits
      title={BENEFITS_TITLE}
      benefits={getSavingsBenefitsList(servicerName, servicer_key || '', preScoreVariation, true)}
      customCSS={customCSS}
    />
  );

  return (
    <section css={heroSectionCSS}>
      <Container>
        <div css={heroWrapperCSS(!isDesktop ? 'unset' : 'baseline')}>
          <BundleHeroMessage
            userName={offer.person.first_name}
            isSecondTestVariation={isSecondTestVariation}
            currentPolicyCarrierName={offer.current_policy.carrier_name}
            bestQuoteCarrierName={activeQuote.carrier.name}
            currentPolicyPremium={offer.current_policy.premium}
            savingValue={activeQuote.saving_value}
            servicerName={servicerName}
            servicerKey={servicer_key}
          />
          <Card customCSS={savingsCardCSS}>
            {isSecondTestVariation ? (
              <SimpleBundleHeader
                setIsTooltipOn={setIsTooltipOn}
                changeToggleState={changeToggleState}
                isTooltipOn={isTooltipOn}
                isToggleOn={isToggleOn}
                onInfoIconClick={() => {
                  analytics.track(SEGMENT.SAVINGS_BUNDLE.INFO_ICON_CLICKED, gid, flow);
                  setIsTooltipOn(true);
                }}
              />
            ) : (
              <PolicyInfoBundleHeader
                isToggleOn={isToggleOn}
                bestQuoteCarrierName={activeQuote.carrier.name}
                carrierLogoUrl={activeQuote.carrier.logo_url}
                savingValue={offer.quotes[0].saving_value}
                bestQuotePremium={activeQuote.premium.value}
              />
            )}

            <div>
              <div css={[offerDetailsCSS, detailsBlockCSS]}>
                {isSecondTestVariation ? (
                  <div>
                    <div css={hero2WrapperCSS}>
                      <img
                        src={activeQuote.carrier.logo_url}
                        alt={activeQuote.carrier.name}
                        height={isMobile ? 38 : 50}
                      />
                      <div css={savingsValueWrapper2CSS}>
                        <div>Save</div>
                        <div css={savingsValue2CSS}>
                          {getPercentRangeValue(offer.quotes[0].saving_value, 15, 30)}
                          <span css={savingsValueSuffixCSS}>/yr</span>
                        </div>
                      </div>
                    </div>

                    <div css={premiumsWrapperCSS}>
                      <StyledDashedRow
                        label="Annual Premium (Est.)"
                        content={Format.money(activeQuote.premium.value || 0, 0, '$')}
                      />
                      <StyledDashedRow
                        label="Current Premium"
                        content={Format.money(offer.current_policy.premium || 0, 0, '$')}
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <h2 css={cardTitleCSS}>Click Continue to Get Started</h2>
                    <BundleCheckBox
                      isToggleOn={isToggleOn}
                      isTooltipOn={isTooltipOn}
                      isSecondTestVariation={isSecondTestVariation}
                      changeToggleState={changeToggleState}
                      setIsTooltipOn={setIsTooltipOn}
                      onInfoIconClick={() => {
                        analytics.track(SEGMENT.SAVINGS_BUNDLE.INFO_ICON_CLICKED, gid, flow);
                        setIsTooltipOn(true);
                      }}
                    />
                  </div>
                )}
              </div>

              <SavingsOffer
                isImplicitDisclosuresAccepted={!!isImplicitDisclosuresAccepted}
                address={offer.address}
                person={offer.person}
                bestQuote={activeQuote}
                currentPolicy={offer.current_policy}
                onSubmit={onFormSubmit}
                isSubmitLoading={isSubmitLoading}
                isBundleMsgActive={true}
              />
            </div>
          </Card>
          {isMobile && (
            <div css={mobileBenefitsWrapperCSS}>
              {geQuotesBenefit({
                container: benefitsWrapperCSS,
                title: benefitsTitleCSS
              })}

              <BBBIcon colorVariation={BBBIconVariation.Blue} customCSS={BBBIconCSS} isSimplifiedVersion />
            </div>
          )}
        </div>
      </Container>
    </section>
  );
};

export default BundleMsgHero;
