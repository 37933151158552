import FAQConstants from 'src/components/FAQ/FAQ.constants';
import SIRV from 'src/constants/sirv';
import { AccordionItem } from 'src/shared/components/Accordion/Accordion.types';

export const accordionItems = [
  {
    key: 'echoPark-with-matic',
    title: 'Why is EchoPark working with Matic Insurance?',
    description: (
      <>
        <p>
          {`At EchoPark Automotive, it's your car, your way. You can buy your car 100% online or in one of our
          coast-to-coast locations. We pair you with an Experience Guide, who walks you through the process from start
          to finish. They answer your questions, match you with the perfect car, set up a test drive and break down
          the finance process.`}
        </p>
        <br />
        <p>
          {`Like our Experience Guides, the expert team of Matic's licensed advisors doesn't work on commission. They
          shop over 40 auto insurance companies, to find you your ideal match, making sure you have coverage that fits
          your needs.`}
        </p>
        <br />
        <p>
          From matching you with your dream car to protecting it down the road, EchoPark and Matic have you covered.
        </p>
      </>
    )
  },
  ...FAQConstants.items
];

export const landingCarriersLogoList = [
  SIRV.CARRIERS.PROGRESSIVE,
  SIRV.CARRIERS.LEMONADE,
  SIRV.CARRIERS.MERCURY,
  SIRV.CARRIERS.ALLSTATE,
  SIRV.CARRIERS.AMERICAN_FAMILY,
  SIRV.CARRIERS.STILLWATER,
  SIRV.CARRIERS.NATIONWIDE,
  SIRV.CARRIERS.TRAVELERS,
  SIRV.CARRIERS.SAFECO,
  SIRV.CARRIERS.PLYMOUTH_ROCK,
  SIRV.CARRIERS.GRANGE,
  SIRV.CARRIERS.CLEARCOVER
];

export const carrierLandingCards = [
  {
    imgSrc: 'https://maticinsurance.sirv.com/illustrations/lady-agent.svg',
    alt: 'Girl',
    label: 'Licensed advisors',
    description: 'Matic’s licensed advisors are here to get the best policy for you and are not paid by premiums sold.'
  },
  {
    imgSrc: 'https://maticinsurance.sirv.com/illustrations/compare-rebrand.svg',
    alt: 'Comparing notes',
    label: 'Gain an advocate',
    description:
      'Matic keeps working for you. We can help manage the paperwork and details of your new policy, ' +
      'and even shop your policy again before renewal.'
  },
  {
    imgSrc: 'https://maticinsurance.sirv.com/illustrations/coffe-rebrand.svg',
    alt: 'Cup of coffee',
    label: 'Sit back and relax',
    description:
      'Let our Matic advisors help you navigate challenges with your carrier. Sign up online or speak to ' +
      'a licensed advisor over the phone to get started.'
  }
];

export const customizedCarrierLandingDisclosure = `*Average bundled savings is based on Matic policyholders 
that purchased a home and auto policy in 2022 when prior insurance and savings were reported.`;

export const unsupportedCarrierDisclosure = `*On average, customers saved $546 on their home and auto policies 
through Matic. Average savings reflect a combination of home and auto insurance products. Homeowner savings 
reflect the difference of Matic customers’ prior insurance policy and their new policy, derived from data where the 
customers prior insurance premium amount is known to Matic. Auto savings contains customers’ self-reported data 
  reflecting the average of the difference between the cost of the prior and new insurance policy. 
  Accounts for all customers who found savings between January 2022 and December 2022.`;

export const getFAQItems = (): AccordionItem[] => [
  {
    key: '1',
    title: 'Where is this offer coming from?',
    description: <span>Matic has partnered with Investopedia to help you shop 40+ A-rated carriers.</span>
  },
  {
    key: '2',
    title: 'How does Matic get compensated?',
    description: (
      <span>
        We get paid a commission directly from the insurance carriers when you switch policies. You’ll only switch if
        you’re getting value so we do our best.
      </span>
    )
  },
  {
    key: '3',
    title: 'Who is Matic?',
    description: <span>Matic is a tech company that specializes in home insurance.</span>
  },
  {
    key: '4',
    title: 'What does Matic charge?',
    description: (
      <span>
        Nothing. Matic gets paid a commission directly from the insurance carrier when you switch policies. You’ll only
        switch if you’re getting value so we do our best.
      </span>
    )
  },
  {
    key: '5',
    title: 'Can I bundle a car?',
    description: <span>Yes, you can bundle your auto and home insurance and save up to 18%.*</span>
  }
];
