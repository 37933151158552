import SIRV from 'src/constants/sirv';
import Button from 'src/kit/Button/Button';
import { reachYouSoonCSS } from './ReachYouSoonModalScreen.style';
import { ReachYouSoonModalScreenProps } from './ReachYouSoonModalScreen.types';

/** @jsxImportSource @emotion/react */
const ReachYouSoonModalScreen: React.FC<ReachYouSoonModalScreenProps> = ({ onModalClosed }) => (
  <div css={reachYouSoonCSS}>
    <img src={SIRV.AGENT_PORTRAIT} alt="Advisor lady portrait" />
    <h2>We will be reaching out soon about your quote!</h2>
    <Button variant="secondary" onClick={onModalClosed}>
      Got It
    </Button>
  </div>
);

export default ReachYouSoonModalScreen;
