/** @jsxImportSource @emotion/react */
import Checkbox from '@mui/material/Checkbox';
import { ClickAwayListener, Tooltip } from '@mui/material';
import InfoIcon from 'src/assets/svg/info.svg';

import {
  infoIconCSS,
  bndlCheckboxWrapperCSS,
  tooltipCustomStyles
} from 'src/components/SavingsHero/BundleMsgHero/BundleMsgHero.style';

interface BundleCheckBoxProps {
  isToggleOn: boolean;
  isTooltipOn: boolean;
  setIsTooltipOn: (isTooltipOn: boolean) => void;
  changeToggleState: () => void;
  onInfoIconClick: () => void;
  isSecondTestVariation?: boolean;
}

const BundleCheckBox: React.FC<BundleCheckBoxProps> = ({
  isToggleOn,
  isTooltipOn,
  isSecondTestVariation,
  changeToggleState,
  setIsTooltipOn,
  onInfoIconClick
}) => {
  const checkBoxCopy = 'Great! Be prepared to discuss your auto policy.';

  return (
    <div>
      <div css={bndlCheckboxWrapperCSS}>
        <Checkbox onClick={changeToggleState} checked={isToggleOn} />
        <span>
          {isSecondTestVariation
            ? 'Have Matic search for auto savings'
            : 'Have Matic search for savings on my home and auto'}
        </span>

        <ClickAwayListener onClickAway={() => setIsTooltipOn(false)}>
          <div>
            <Tooltip
              PopperProps={{
                disablePortal: true
              }}
              onClose={() => setIsTooltipOn(false)}
              open={isTooltipOn}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              arrow
              componentsProps={tooltipCustomStyles(true)}
              placement="top"
              title="When Matic shops home and auto, customers save up to 20%."
            >
              <div onClick={onInfoIconClick} role="presentation">
                <img css={infoIconCSS} src={InfoIcon} alt="Information icon" />
              </div>
            </Tooltip>
          </div>
        </ClickAwayListener>
      </div>
      {isToggleOn && <p>{checkBoxCopy}</p>}
    </div>
  );
};

export default BundleCheckBox;
