/** @jsxImportSource @emotion/react */

import React, { useState } from 'react';
import Dialog from 'src/kit/Dialog/Dialog';
import {
  contentCSS,
  headerCSS,
  closeCSS,
  backButtonCSS,
  containerCSS,
  wrapperCSS,
  overlayCSS,
  coverageCSS
} from './QuoteSideCardModal.style';
import { QuoteSideCardModalProps, QuoteSideCardModalScreens } from './QuoteSideCardModal.types';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { Flows } from 'src/interfaces/IPage';
import useResponsive from 'src/hooks/useResponsive';
import CloseIcon from 'src/assets/svg/close.svg?react';
import BackIcon from 'src/assets/svg/arrow_back.svg?react';
import DefaultModalScreen from './DefaultModalScreen/DefaultModalScreen';
import { getHomeQuotesCoveragesGroups } from 'src/utils/quotesPage';
import QuoteCoverages from 'src/components/Quotes/QuoteCoverages/QuoteCoverages';
import { logoTagWrapperCSS } from './DefaultModalScreen/DefaultModalScreen.style';
import QuoteLogo from 'src/components/Quotes/QuoteCard/components/QuoteLogo/QuoteLogo';
import { QuoteLogoSize } from 'src/components/Quotes/QuoteCard/components/QuoteLogo/QuoteLogo.types';
import ScheduleCallModalScreen from './ScheduleCallModalScreen/ScheduleCallModalScreen';
import ReachYouSoonModalScreen from './ReachYouSoonModalScreen/ReachYouSoonModalScreen';

const QuoteSideCardModal: React.FC<QuoteSideCardModalProps> = ({
  gid,
  onModalClose,
  quote,
  ordinal,
  onQuoteSelect,
  isOpen,
  actionButtonLabel = 'Schedule a Call',
  isDigitalFunnel = false
}) => {
  const { isMobile } = useResponsive();
  const [screenToRender, setScreenToRender] = useState(QuoteSideCardModalScreens.Default);

  const onModalClosed = () => {
    analytics.track(SEGMENT.QUOTE_SIDE_MODAL.CLOSED, gid, Flows.AllFunnel);
    onModalClose();
  };

  const onBackButtonClicked = () => {
    analytics.track(SEGMENT.QUOTE_SIDE_MODAL.BACK_BUTTON_CLICKED, gid, Flows.AllFunnel);
    setScreenToRender(QuoteSideCardModalScreens.Default);
  };

  const onQuoteSelected = () => {
    onQuoteSelect(quote, ordinal);
    analytics.track(SEGMENT.QUOTE_SIDE_MODAL.QUOTE_SELECTED, gid, Flows.AllFunnel);
  };

  const headerCopy = screenToRender === QuoteSideCardModalScreens.ScheduleCall ? 'Schedule a Call' : '';
  const renderExtendedModal = screenToRender === QuoteSideCardModalScreens.ScheduleCall && !isMobile;

  const renderScreen = () => {
    switch (screenToRender) {
      case QuoteSideCardModalScreens.ScheduleCall:
        return <ScheduleCallModalScreen />;
      case QuoteSideCardModalScreens.ReachYouSoon:
        return <ReachYouSoonModalScreen onModalClosed={onModalClosed} />;
      case QuoteSideCardModalScreens.Default:
      default:
        return (
          <DefaultModalScreen
            gid={gid}
            quote={quote}
            onQuoteSelected={onQuoteSelected}
            changeScreen={setScreenToRender}
            actionButtonLabel={actionButtonLabel}
            isDigitalFunnel={isDigitalFunnel}
          />
        );
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      size="small"
      shouldCloseOnOverlayClick
      shouldHideCloseIcon
      transitionDuration={600}
      onRequestClose={onModalClosed}
      customCSS={{
        body: containerCSS(renderExtendedModal, screenToRender === QuoteSideCardModalScreens.ScheduleCall),
        dialogInline: { content: contentCSS(renderExtendedModal), overlay: overlayCSS }
      }}
    >
      <div css={wrapperCSS(renderExtendedModal)}>
        <div>
          <div css={headerCSS}>
            <div css={backButtonCSS}>
              {QuoteSideCardModalScreens.ScheduleCall === screenToRender && <BackIcon onClick={onBackButtonClicked} />}
              {headerCopy}
            </div>

            <CloseIcon css={closeCSS} onClick={onModalClosed} />
          </div>

          {![QuoteSideCardModalScreens.ReachYouSoon, QuoteSideCardModalScreens.ScheduleCall].includes(
            screenToRender
          ) && (
            <div css={logoTagWrapperCSS}>
              <QuoteLogo size={QuoteLogoSize.BIG} logoUrl={quote.carrier.logo_url} logoLabel={quote.carrier.name} />
            </div>
          )}
        </div>

        {renderScreen()}

        {QuoteSideCardModalScreens.ScheduleCall !== screenToRender && (
          <div css={coverageCSS}>
            <QuoteCoverages groups={getHomeQuotesCoveragesGroups(quote.coverages)} shouldRenderDescription />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default QuoteSideCardModal;
