import api from 'src/api/api';
import { API_URLS } from 'src/constants/api';
import { getApiUrl } from 'src/utils/api.helpers';

const answersService = {
  updateAnswers(gid: string, bundle_interest: string) {
    return api.post(getApiUrl(API_URLS.ANSWERS, gid), {
      body: {
        gid,
        person_has_core_bundle_interest: bundle_interest
      }
    });
  }
};

export default answersService;
