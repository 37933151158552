import { Flows } from './IPage';
import { Answers } from './IAnswer';
import { AddressAnswer } from './IQuestion';
import { SavingsQuote } from 'src/interfaces/IQuotes';

type QuotingSessionStatus = 'pending' | 'in_progress' | 'finished';

export enum FloridaIneligibilityReasons {
  Claims = 'claims',
  Roof = 'roof',
  Zip = 'zip',
  HomeAge = 'home_age',
  HomeownersInsurance = 'homeowners_insurance'
}

export interface SessionUpdatePayload {
  answers: Answers;
  quotes_request?: boolean;
  step?: string;
  recaptcha_token?: string | null;
}

interface CurrentPolicy {
  carrier_name: string;
  days_till_policy_expired: number | null;
  deductible: number | null;
  dwelling: number | null;
  expiration_date: string | null;
  premium: number;
}

export type ChartDataDto = [string, string][];
export type PremiumTypeDto = 'user_data' | 'carrier_data' | 'state_data';

export type HistoricalPremiumDto = {
  chart_data: ChartDataDto;
  predicted_user_premium_amount: number;
  premium_change_amount: number;
  premium_change_percent: number;
  premium_type: PremiumTypeDto;
};

export type SessionStatus = 'disqualified' | 'active' | 'inactive';

export interface Session {
  gid: string;
  flow: Flows;
  status: SessionStatus;
  quoting_status: QuotingSessionStatus;
  florida_ineligibility_reason: FloridaIneligibilityReasons;
  current_policy?: CurrentPolicy;
  bundle_status?: string;
  predicted_quote_savings?: number | null;
  quotes?: SavingsQuote[];
  render_skip_to_home_quotes_button?: boolean;
  historical_premium?: HistoricalPremiumDto;
  address?: AddressAnswer;
  digital_profile_url?: string;
}

export enum SessionUnlockStatus {
  Success = 'success',
  Invalid = 'invalid'
}

export type SessionUnlock = {
  status: SessionUnlockStatus;
  locked_attempts: number;
  message: string | null;
  phone?: string;
  remain_attempts: number;
};
