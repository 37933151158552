/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useRef, useState, Fragment } from 'react';
import { useParams } from 'react-router-dom';

import {
  componentsBackgroundCSS,
  componentsPaddingCSS,
  faqCSS,
  heroCSS,
  heroFormWrapperCustomCSS,
  heroHeadingCustomCSS,
  profileHeadingCss,
  userProfileCSS,
  userProfileWrapperCSS
} from './AllFunnelLanding.style';

import { DefaultLocation } from 'src/interfaces/IPage';
import useConfig from 'src/api/config/useConfig';
import useResponsive from 'src/hooks/useResponsive';
import FullPageLoader from 'src/shared/components/FullPageLoader/FullPageLoader';
import Container from 'src/kit/Container/Container';
import GoogleReviews from 'src/components/Landing/GoogleReviews/GoogleReviews';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import CarriersList from 'src/components/CarriersList/CarriersList';
import HowItWorksSteps from 'src/components/HowItWorksSteps/HowItWorksSteps';
import FAQ from 'src/components/FAQ/FAQ';
import { getAge } from 'src/utils/date';
import useFeatureToggle from 'src/hooks/useFeatureToggle/useFeatureToggle';
import { AddressAnswer } from 'src/interfaces/IQuestion';
import { Answers } from 'src/interfaces/IAnswer';
import WhyTrustMatic from 'src/components/WhyTrustMatic/WhyTrustMatic';
import AllFunnelOfferForm from 'src/components/AllFunnelOfferForm/AllFunnelOfferForm';
import {
  getAccordionItems,
  getCarriersListHeader,
  getCarriersListSubheader
} from 'src/components/Savings/Savings.utils';
import { Offer } from 'src/interfaces/ISavings';
import { getAllFunnelLookupSteps } from 'src/components/HowItWorksSteps/HowItWorksSteps.utils';
import SERVICERS, { getServicerNameToDisplay } from 'src/constants/servicers';
import QuotesUserProfile from 'src/components/Quotes/StandardHouseQuotes/QuotesUserProfile/QuotesUserProfile';
import { useCustomNavigate } from 'src/hooks/useCustomNavigate';
import { Disclosure } from 'src/interfaces/disclosures.types';
import { isPhoneHidden } from 'src/utils/phone';
import { useMutateSession } from 'src/api/session';
import { allFunnelService } from 'src/api/allFunnel';
import { useDisclosures } from 'src/api/disclosures';
import { AFAnswers } from 'src/components/AllFunnelOfferForm/AllFunnelOfferForm.types';
import useQuestions from 'src/api/questions/useQuestions';
import { useSession } from 'src/api/session';
import { EXPERIMENT_VARIANTS_ENUM, FEATURE_TOGGLES_IDS_ENUM as FT } from 'src/interfaces/experiment.types';
import { getReadableAddress } from 'src/utils/homeDetails';
import HistoricalPremium from 'src/components/HistoricalPremium/HistoricalPremium';
import SavingsHero from 'src/components/SavingsHero/SavingsHero';
import { ICurrentPolicy, SavingsQuote } from 'src/interfaces/IQuotes';
import QuoteBenefits from 'src/components/QuoteBenefits/QuoteBenefits';
import { getSavingsBenefitsList } from 'src/components/SavingsHero/SavingHero.utils';
import { SavingSelectedQuote } from 'src/interfaces/ISavings';
import savingsService from 'src/api/savingsQuotes/savings.service';
import { googleReviewsCSS } from 'src/components/SavingsMainContent/SavingsMainContent.style';

const AllFunnelLanding: React.FC = () => {
  const { gid, flow } = useParams() as DefaultLocation;
  const { isMobile } = useResponsive();
  const navigate = useCustomNavigate();

  const { data: config, isLoading: isConfigLoading } = useConfig(gid);
  const { data: disclosuresData } = useDisclosures(gid);
  const { data: questions } = useQuestions(gid);
  const answers = useMemo(() => questions?.answers || ({} as Answers), [questions]);
  const {
    stories,
    person_phone,
    person_first_name,
    person_last_name,
    property_address,
    current_policy,
    property_year_built,
    property_square_feet,
    person_marital_status,
    person_date_of_birth,
    person_email,
    person_gid
  } = answers as AFAnswers;
  const { data: sessionData, isLoading: isSessionDataLoading } = useSession(flow, gid);
  const { mutateAsync: mutateSession, isLoading } = useMutateSession(flow, gid);

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isUserPhoneUpdated, setIsUserPhoneUpdated] = useState(false);
  const [isHistoricalPremiumExpActive, setHistoricalPremiumExpActive] = useState(false);
  const [shouldRenderPreScoreVariation, setShouldRenderPreScoreVariation] = useState(false);
  const [isFreedomSFVariation, setIsFreedomSFVariation] = useState(false);

  const isPhhNewRezServicer = config?.servicer_key === SERVICERS.PHH_NRZ;
  const isNewRezServicer = config?.servicer_key === SERVICERS.NEW_REZ_NRZ;
  const servicerName = getServicerNameToDisplay(config?.servicer_key, config?.servicer_name);
  const flowStartedReported = useRef(false);
  const alreadyTrackedExperiments = useRef<FT[]>([]);
  const features = useFeatureToggle();

  const preScoreOffer: Offer = {
    address: property_address as AddressAnswer,
    person: {
      first_name: person_first_name as string,
      last_name: person_last_name as string,
      date_of_birth: person_date_of_birth as string,
      marital_status: person_marital_status as string,
      phone: person_phone as string,
      email: person_email as string,
      person_gid: person_gid as string
    },
    quotes: sessionData?.quotes as SavingsQuote[],
    current_policy: current_policy as ICurrentPolicy
  };

  useEffect(() => {
    if (!flowStartedReported.current && config?.servicer_key) {
      analytics.track(SEGMENT.FLOW_STARTED, gid, flow, {
        partner_key: config?.servicer_key
      });
      flowStartedReported.current = true;
    }
  }, [flow, gid, config]);

  useEffect(() => {
    if (answers.person_gid && config?.servicer_key) {
      const address = answers.property_address as AddressAnswer;

      analytics.identify(answers.person_gid, {
        email: analytics.generateTrackEmail(answers.person_gid as string),
        state: address?.state ?? undefined,
        age: answers.person_date_of_birth ? getAge(answers.person_date_of_birth as string) : undefined
      });

      analytics.page(SEGMENT.PAGES.All_FUNNEL, {
        flow_type: flow,
        session_gid: gid,
        partner_key: config?.servicer_key
      });
    }
  }, [answers, flow, gid, config]);

  useEffect(() => {
    const preScoreExp = features?.[FT.AF_PRESCORE_PREMIUM];
    const dtqPreScoreExp = features?.[FT.DTQ_AF_PRESCORE_PREMIUM];
    const historicalPremiumExp = features?.[FT.AF_HISTORICAL_PREMIUM];
    const freedomSFToSingleQuote = features?.[FT.FREEDOM_SF_TO_SINGLE_QUOTE];

    const experimentsToTrack = [
      {
        experiment: preScoreExp,
        callback: () => {
          setHistoricalPremiumExpActive(!preScoreExp?.isControlVariation);
          setShouldRenderPreScoreVariation(!preScoreExp?.isControlVariation);
        }
      },
      {
        experiment: historicalPremiumExp,
        callback: () => setHistoricalPremiumExpActive(!historicalPremiumExp?.isControlVariation)
      },
      {
        experiment: dtqPreScoreExp,
        callback: () => {
          setHistoricalPremiumExpActive(!dtqPreScoreExp?.isControlVariation);
          setShouldRenderPreScoreVariation(!dtqPreScoreExp?.isControlVariation);
        }
      },
      {
        experiment: freedomSFToSingleQuote,
        callback: () => setIsFreedomSFVariation(!freedomSFToSingleQuote?.isControlVariation)
      }
    ];

    experimentsToTrack.forEach(({ experiment, callback }) => {
      !alreadyTrackedExperiments.current.includes(experiment?.name as FT) &&
        experiment?.track(() => {
          callback?.();
          alreadyTrackedExperiments.current.push(experiment.name);
        });
    });
  }, [features, sessionData?.historical_premium]);

  const onGoogleReviewsClick = () =>
    analytics.track(SEGMENT.GOOGLE_REVIEWS_CLICKED, gid, flow, {
      layout: isMobile ? 'mobile' : 'desktop',
      location: SEGMENT.PAGES_KEY.ALL_FUNNEL
    });

  const handleSubmit = async (_phone: string | null) => {
    const AFToDPExp = features?.[FT.AF_TO_DP];

    analytics.track(SEGMENT.PRIMARY_CTA_CLICKED, gid, flow);

    await allFunnelService.createLead(gid);

    disclosuresData?.disclosures?.forEach((disclosure: Disclosure) => {
      analytics.track(SEGMENT.DISCLOSURE_ACCEPTED, gid, flow, {
        disclosure_gid: disclosure.gid,
        page: SEGMENT.PAGES_KEY.ALL_FUNNEL
      });
    });

    analytics.track(SEGMENT.QUOTES_REQUESTED, gid, flow);

    const answersToUpdate = questions?.answers || ({} as Answers);
    const userPhone = answersToUpdate.person_phone as string;

    if (userPhone && isPhoneHidden(userPhone)) {
      delete answersToUpdate.person_phone;
    }

    await mutateSession({ quotes_request: true, answers: answersToUpdate });

    // TODO refactor Freedom to AF for olb/dp links if needed
    if (AFToDPExp?.isEnabled && !isFreedomSFVariation) {
      AFToDPExp?.track();

      if (AFToDPExp?.variation === EXPERIMENT_VARIANTS_ENUM.TEST_A) {
        analytics.track(SEGMENT.DIGITAL_PROFILE_URL_REDIRECTED, gid, flow);
        window.open(sessionData?.digital_profile_url, '_self');
        return;
      }
    }

    if (isFreedomSFVariation) {
      return navigate(`/${flow}/${gid}/quotes`, {
        state: {
          isAFRedirected: true
        },
        replace: true
      });
    }

    return navigate(`/${flow}/${gid}/interstitial`, {
      state: {
        isAFRedirected: true
      },
      replace: true
    });
  };

  const acceptOffer = (data: any) => {
    return savingsService.selectQuote(gid, data);
  };

  const onPreScoreFormSubmit = (quote: SavingsQuote, phone: string) => {
    const updatedData: SavingSelectedQuote = {
      gid,
      quote_gid: quote.gid
    };

    if (phone !== preScoreOffer?.person.phone) {
      analytics.track(SEGMENT.PHONE_CHANGE_ATTEMPTED, gid, flow, {
        resolution: SEGMENT.CHANGE_ATTEMPTED_RESOLUTION.SUCCESS
      });
      updatedData.phone = phone;
    }

    const onDigitalProfile = async (url: string, data: any) => {
      await acceptOffer(data);

      analytics.track(SEGMENT.DIGITAL_PROFILE_URL_REDIRECTED, gid, flow);
      window.open(url, '_self');
      return;
    };

    const onBuyOnline = async (onlineBindUrl: string, data: any) => {
      try {
        await acceptOffer(data);
        window.location.replace(onlineBindUrl);
      } catch {}
    };

    const onCallMe = async (data: any) => {
      setIsSubmitLoading(true);
      await acceptOffer(data);

      navigate(`/${flow}/${gid}/confirmation`);
    };

    if (shouldRenderPreScoreVariation && sessionData?.quotes?.length) {
      const onlineBindUrl = sessionData?.quotes[0]?.online_bind_url;
      const digitalProfileUrl = sessionData?.quotes[0]?.digital_profile_url;

      setIsSubmitLoading(true);

      digitalProfileUrl
        ? onDigitalProfile(digitalProfileUrl, updatedData)
        : onlineBindUrl
          ? onBuyOnline(onlineBindUrl, updatedData)
          : onCallMe(updatedData);
    }
  };

  if ((!isUserPhoneUpdated && isConfigLoading) || !config?.gid || isSessionDataLoading) {
    return <FullPageLoader />;
  }

  return (
    <div>
      {shouldRenderPreScoreVariation && preScoreOffer?.quotes?.length ? (
        <SavingsHero
          offer={preScoreOffer}
          preScoreVariation
          isSubmitLoading={isSubmitLoading}
          onSubmit={onPreScoreFormSubmit}
          predictedPremiumChange={sessionData?.historical_premium?.premium_change_amount}
          predictedPremium={sessionData?.historical_premium?.predicted_user_premium_amount}
          estimatedSavings={sessionData?.predicted_quote_savings}
        />
      ) : (
        <div css={heroCSS(isHistoricalPremiumExpActive)}>
          <AllFunnelOfferForm
            onSubmit={handleSubmit}
            onUserPhoneUpdated={setIsUserPhoneUpdated}
            phoneValue={person_phone ?? null}
            isPageSubmitting={isLoading}
            customCSS={
              isHistoricalPremiumExpActive
                ? {
                    heading: heroHeadingCustomCSS,
                    formWrapper: heroFormWrapperCustomCSS
                  }
                : {}
            }
          />
        </div>
      )}

      <Container customCSS={componentsPaddingCSS} id={shouldRenderPreScoreVariation ? 'premium-chart' : ''}>
        {isHistoricalPremiumExpActive || shouldRenderPreScoreVariation ? (
          <HistoricalPremium
            {...sessionData?.historical_premium}
            currentPolicyAmount={current_policy?.premium}
            currentCarrier={current_policy?.carrier_name}
            address={getReadableAddress(answers.property_address as AddressAnswer)}
            personName={`${answers.person_first_name} ${answers.person_last_name}`}
            yearBuild={answers.property_year_built}
            squareFeet={answers.property_square_feet}
            stories={answers.stories}
            state={(answers.property_address as AddressAnswer)?.state}
          />
        ) : (
          <Fragment>
            <h2 css={profileHeadingCss}>It’s easy as 1, 2, 3</h2>
            <div css={userProfileCSS}>
              <QuotesUserProfile
                customCSS={userProfileWrapperCSS}
                firstName={person_first_name}
                lastName={person_last_name}
                carrierName={current_policy?.carrier_name}
                currentPolicy={current_policy}
                homeDetails={{
                  yearBuilt: property_year_built,
                  squareFeet: property_square_feet,
                  stories: stories
                }}
                address={property_address}
              />

              <HowItWorksSteps
                heading={isMobile ? 'It’s easy as 1, 2, 3' : ''}
                steps={getAllFunnelLookupSteps(isNewRezServicer)}
              />
            </div>
          </Fragment>
        )}
      </Container>

      <Container customCSS={componentsPaddingCSS}>
        {isMobile && shouldRenderPreScoreVariation && (
          <QuoteBenefits
            title="Benefits of switching with Matic"
            benefits={getSavingsBenefitsList(
              servicerName,
              config?.servicer_key || '',
              false,
              shouldRenderPreScoreVariation
            )}
          />
        )}
      </Container>

      <div css={[componentsBackgroundCSS, componentsPaddingCSS]}>
        <Container>
          <CarriersList
            header={getCarriersListHeader(config.servicer_key, servicerName)}
            subheader={getCarriersListSubheader(isPhhNewRezServicer, servicerName)}
          />
        </Container>
      </div>

      <Container customCSS={componentsPaddingCSS}>
        <GoogleReviews
          customCSS={{ wrapper: googleReviewsCSS }}
          heading="Why homeowners trust Matic"
          onGoogleReviewsClick={onGoogleReviewsClick}
        />
      </Container>

      <div css={[componentsBackgroundCSS, componentsPaddingCSS]}>
        <Container>
          <WhyTrustMatic savings={config?.partner.savings_label ?? null} isNewRez={isNewRezServicer} />
        </Container>
      </div>

      <Container customCSS={componentsPaddingCSS}>
        <FAQ customCSS={faqCSS} page={SEGMENT.PAGES.All_FUNNEL} accordionItems={getAccordionItems(servicerName)} />
      </Container>
    </div>
  );
};

export default AllFunnelLanding;
