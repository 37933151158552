import { css, SerializedStyles } from '@emotion/react';
import { colors as c, mq } from 'src/theme/variables';
import { text24 } from 'src/theme/typography';

export const contentCSS = (extendedWidth?: boolean): React.CSSProperties => {
  const width = extendedWidth ? 1074 : 550;

  return {
    right: 0,
    position: 'absolute',
    maxWidth: width,
    overflowX: 'hidden',
    minHeight: '100%'
  };
};

export const containerCSS = (extendedWidth?: boolean, scheduleCallMode?: boolean): SerializedStyles => css`
  height: 100vh;
  box-sizing: border-box;
  border-radius: 0;

  ${extendedWidth &&
  css`
    padding: calc(40px + var(--demo-flow-space) + var(--browser-update-height) 16px 40px !important;
  `}

  ${scheduleCallMode && 'overflow-y: scroll; overflow-x: hidden'}
`;

export const overlayCSS: React.CSSProperties = {
  padding: 0
};

export const headerCSS = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

export const closeCSS = css`
  display: flex;
  align-items: center;
  cursor: pointer;

  path {
    stroke: ${c.black};
  }
`;

export const backButtonCSS = css`
  ${text24};
  display: flex;
  align-items: center;
  font-weight: 700;

  svg {
    margin-right: 24px;
    cursor: pointer;
  }
`;

export const wrapperCSS = (extendedWidth?: boolean): SerializedStyles => css`
  ${!extendedWidth &&
  `display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;`}
`;

export const coverageCSS = css`
  background: ${c.grayFive};
  margin: 0 -40px -40px;
  padding: 20px 50px;
  flex-shrink: 1;

  ${mq[0]} {
    height: 100%;
    padding: 50px;
  }
`;
