/** @jsxImportSource @emotion/react */
import answersService from 'src/api/answers/answers.service';
import SEGMENT from 'src/constants/segment';
import { DefaultLocation } from 'src/interfaces/IPage';
import Checkbox from '@mui/material/Checkbox';
import { ClickAwayListener, Tooltip } from '@mui/material';
import analytics from 'src/utils/analytics';
import InfoIcon from 'src/assets/svg/info.svg';
import { useState, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import {
  bndlCheckboxWrapperCSS,
  containerCSS,
  heroImgCSS,
  heroImgContainerCSS,
  heroInfoCSS,
  heroTitleCSS,
  infoIconCSS,
  tooltipCustomStyles,
  checkBoxDescriptionCSS
} from './BundleMsgPageHero.style';
import BundleOffIcon from 'src/assets/svg/bundle-picture-disabled.svg';
import BundleOnGroupIcon from 'src/assets/svg/bundle-picture-active.svg';
import HouseAndCarIcon from 'src/assets/svg/house-and-car.svg';
import useResponsive from 'src/hooks/useResponsive';

interface BundleMsgPageHeroProps {
  isToggleOn: boolean;
  setIsToggleOn: React.Dispatch<React.SetStateAction<boolean>>;
}

const BundleMsgPageHero: React.FC<BundleMsgPageHeroProps> = ({ isToggleOn, setIsToggleOn }) => {
  const { gid, flow } = useParams() as DefaultLocation;
  const { isMobile } = useResponsive();
  const [isTooltipOn, setIsTooltipOn] = useState(false);

  const changeToggleState = async () => {
    setIsToggleOn((prevState: boolean) => {
      const isToggleOn = !prevState;
      const customerBundleChoice = isToggleOn ? 'yes' : 'no';

      answersService.updateAnswers(gid, customerBundleChoice);
      analytics.track(SEGMENT.AF_BUNDLE.TOGGLED_CLICKED, gid, flow, {
        toggle_state: isToggleOn ? 'on' : 'off'
      });
      return isToggleOn;
    });
  };

  const getCheckBox = () => (
    <Fragment>
      <div css={bndlCheckboxWrapperCSS}>
        <Checkbox onChange={changeToggleState} checked={isToggleOn} />
        <span>
          <img src={HouseAndCarIcon} alt="House plus car icon" />
          {isMobile ? 'Shop my auto policy, too' : 'Have Matic search for savings on my home and auto'}
        </span>

        <ClickAwayListener onClickAway={() => setIsTooltipOn(false)}>
          <div>
            <Tooltip
              PopperProps={{
                disablePortal: true
              }}
              onClose={() => setIsTooltipOn(false)}
              open={isTooltipOn}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              arrow
              componentsProps={tooltipCustomStyles(true)}
              placement="top"
              title="When Matic shops home and auto, customers save up to 20%."
            >
              <div
                onClick={() => {
                  analytics.track(SEGMENT.AF_BUNDLE.INFO_ICON_CLICKED, gid, flow);
                  setIsTooltipOn(true);
                }}
                role="presentation"
              >
                <img css={infoIconCSS} src={InfoIcon} alt="Information icon" />
              </div>
            </Tooltip>
          </div>
        </ClickAwayListener>
      </div>
      {isToggleOn && <p css={checkBoxDescriptionCSS}>Great! Be prepared to discuss your auto policy.</p>}
    </Fragment>
  );

  const getImages = () => (
    <div css={heroImgContainerCSS}>
      <img css={heroImgCSS(true, isToggleOn)} src={BundleOffIcon} alt="Disabled bundle" />
      <img css={heroImgCSS(false, isToggleOn)} src={BundleOnGroupIcon} alt="Enabled bundle" />
    </div>
  );

  return (
    <div css={containerCSS}>
      <div css={heroInfoCSS}>
        <div>
          <div css={heroTitleCSS}>Did you know? Matic can find you savings on auto, too.</div>
          {isMobile && getImages()}
          {getCheckBox()}
        </div>
      </div>
      {!isMobile && getImages()}
    </div>
  );
};

export default BundleMsgPageHero;
