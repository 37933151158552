/** @jsxImportSource @emotion/react */
import React from 'react';

import { QuoteCardKeyValuesProps } from './QuoteCardKeyValues.types';
import {
  containerCSS,
  labelRowCSS,
  metadataWrapperCSS,
  premiumRowCSS,
  valueRowCSS,
  bndlMsqPremiumValueCSS
} from './QuoteCardKeyValues.style';
import Format from 'src/utils/format';
import { getPremiumLabelByType } from 'src/utils/quotesPage';
import useResponsive from 'src/hooks/useResponsive';
import { AUTOMATION_TEST_IDS } from 'src/constants/automationTestIds';

const QuoteCardKeyValues: React.FC<QuoteCardKeyValuesProps> = ({
  customCSS,
  premium,
  deductible,
  highlightPremium,
  multiPolicyPremium,
  isBndToggleOn
}) => {
  const { isMobile } = useResponsive();

  const premiumValue = isBndToggleOn
    ? Format.money(multiPolicyPremium || premium.value, 0, '$')
    : Format.money(premium.value, 0, '$');

  const bndlMsgQuote = isBndToggleOn && !!multiPolicyPremium;

  return (
    <div css={[containerCSS, customCSS]}>
      {!isMobile && (
        <div css={metadataWrapperCSS(false)}>
          {<div css={valueRowCSS}>{Format.money(deductible, 9, '$')}</div>}
          <div css={labelRowCSS()}>Deductible</div>
        </div>
      )}
      <div css={metadataWrapperCSS(bndlMsgQuote)}>
        {bndlMsgQuote && <div css={bndlMsqPremiumValueCSS}>{Format.money(premium.value, 0, '$')}</div>}
        <div css={[valueRowCSS, premiumRowCSS(highlightPremium)]} data-testid={AUTOMATION_TEST_IDS.QUOTE.PREMIUM}>
          {premiumValue}
        </div>
        <div css={labelRowCSS(highlightPremium)}>{getPremiumLabelByType(premium.type)}</div>
      </div>
    </div>
  );
};

export default QuoteCardKeyValues;
