import { css } from '@emotion/react';
import { text, text14, text24, text40 } from 'src/theme/typography';
import { mq, colors as c } from 'src/theme/variables';

export const headerGroupCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-height: 250px;
  padding-bottom: 32px;
  padding-top: 19px;

  ${mq[0]} {
    height: 330px;
    margin-top: 24px;
  }
`;

export const premiumCSS = css`
  ${text40};
  font-weight: 700;

  ${mq[0]} {
    font-size: 60px;
    font-weight: 900;
  }
`;

export const deductibleCSS = css`
  ${text24};
  display: none;
  font-weight: 700;
  margin: 12px 0 0;

  ${mq[0]} {
    display: block;
  }
`;

export const priceWrapperCSS = css`
  display: flex;
  align-items: center;
`;

export const savingCSS = css`
  ${text14};
  color: ${c.green};
  font-weight: 700;
  max-width: 80px;

  ${mq[0]} {
    ${text};
  }
`;

export const descriptionCSS = css`
  color: ${c.dove};
  margin-top: 10px;
`;

export const buttonsWrapperCSS = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  row-gap: 12px;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 25px;

  ${mq[0]} {
    width: 93%;
    height: auto;
    flex-direction: row;
    margin-top: 32px;
  }
`;

export const buttonCSS = css`
  width: 100%;
  max-width: 90vw;

  ${mq[0]} {
    width: 200px;
  }
`;

export const savingsWrapperCSS = css`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const logoTagWrapperCSS = css`
  display: flex;
  margin-top: 10px;
  justify-content: center;

  ${mq[0]} {
    margin-top: 22px;
  }
`;
