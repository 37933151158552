import { css } from '@emotion/react';
import { text, text14, text32, text48 } from 'src/theme/typography';
import { mq, colors as c } from 'src/theme/variables';
import { TooltipComponentsPropsOverrides } from '@mui/material';
import { tooltipArrowCSS, tooltipCSS } from 'src/shared/components/Tooltip/Tooltip.style';

export const infoIconCSS = css`
  cursor: pointer;
  margin-left: 8px;
  margin-top: 4px;
`;

export const bndlCheckboxWrapperCSS = css`
  display: flex;
  width: 100%;
  align-items: center;
  color: ${c.black};
  justify-content: center;

  .MuiButtonBase-root {
    margin-left: -8px;
    position: relative;
    z-index: 0;

    :after {
      z-index: -1;
      position: absolute;
      content: '';
      height: 18px;
      width: 18px;
      background-color: ${c.white};
      border-radius: 3px;
    }
  }

  ${mq[0]} {
    margin-top: -8px;
    justify-content: flex-start;

    .MuiButtonBase-root {
      margin-top: 0;
    }
  }

  span {
    display: flex;
    align-items: center;
    gap: 8px;

    ${text};
  }
`;

export const checkBoxDescriptionCSS = css`
  color: ${c.black};
  ${text14};
  text-align: center;

  ${mq[0]} {
    text-align: left;
  }
`;

export const tooltipCustomStyles = (isMobile: boolean): TooltipComponentsPropsOverrides => ({
  tooltip: {
    sx: {
      ...tooltipCSS,
      maxWidth: `${isMobile ? 245 : 356}px`,
      fontSize: '14px',
      lineHeight: '20px',
      color: c.black,
      border: `1px solid ${c.silver}`,
      padding: '20px',
      boxShadow: '2px 4px 0px 0px rgba(0, 0, 0, 0.12)'
    }
  },
  arrow: {
    sx: {
      ...tooltipArrowCSS,
      '&::before': {
        border: `1px solid ${c.silver}`,
        backgroundColor: '#fff',
        boxSizing: 'border-box'
      }
    }
  }
});

export const heroTitleCSS = css`
  font-weight: 900;
  ${text32};
  margin-bottom: 24px;

  ${mq[0]} {
    ${text48};
    line-height: 60px;
  }
`;

export const heroInfoCSS = css`
  max-width: 532px;
`;

export const containerCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  padding: 40px 24px;
  ${mq[0]} {
    border-radius: 16px;
    margin-top: 32px;
    flex-direction: row;
    padding: 56px;
  }

  background-color: ${c.powderBlue};
  color: ${c.mineshaft};
`;

export const heroImgContainerCSS = css`
  position: relative;
  width: 100%;
  height: 115px;
  margin-bottom: 23px;

  img {
    height: 115px;
  }

  ${mq[1]} {
    width: 40%;
    height: auto;
    margin-bottom: 0;

    img {
      height: auto;
    }
  }
`;

export const heroImgCSS = (isDisabled: boolean, isToggleOn: boolean) => css`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;

  ${mq[1]} {
    margin: auto unset;
    left: unset;
  }

  ${isDisabled &&
  css`
    z-index: 2;
    opacity: 1;
    transition: opacity 0.25s ease;

    ${isToggleOn &&
    css`
      opacity: 0;
      transition: opacity 0.25s ease;
    `}
  `}
`;
