/** @jsxImportSource @emotion/react */
import React from 'react';

import {
  hero2CSS,
  img2CSS,
  headingCSS,
  subWrapperCSS
} from 'src/components/SavingsHero/BundleMsgHero/BundleMsgHero.style';
import BundleCheckBox from 'src/components/BundleMsgComponents/BundleCheckBox/BundleCheckBox';
import BundleOffIcon from 'src/assets/svg/bundle-picture-disabled.svg';
import BundleOnGroupIcon from 'src/assets/svg/bundle-picture-active.svg';

interface SimpleBundleHeaderProps {
  isToggleOn: boolean;
  isTooltipOn: boolean;
  setIsTooltipOn: (isTooltipOn: boolean) => void;
  changeToggleState: () => void;
  onInfoIconClick: () => void;
  isSingleQuoteVariation?: boolean;
}

export const SimpleBundleHeader: React.FC<SimpleBundleHeaderProps> = ({
  isToggleOn,
  isTooltipOn,
  changeToggleState,
  setIsTooltipOn,
  onInfoIconClick,
  isSingleQuoteVariation = false
}) => {
  const heading = isSingleQuoteVariation
    ? 'Did you know? Matic can find you savings on auto, too.'
    : 'Did you know? Matic can look for savings on your auto policy, too.';
  return (
    <div css={hero2CSS}>
      <div css={subWrapperCSS}>
        <div css={headingCSS}> {heading}</div>
        <BundleCheckBox
          isToggleOn={isToggleOn}
          isTooltipOn={isTooltipOn}
          isSecondTestVariation={true}
          changeToggleState={changeToggleState}
          setIsTooltipOn={setIsTooltipOn}
          onInfoIconClick={onInfoIconClick}
        />
      </div>

      <img css={img2CSS(true, isToggleOn)} src={BundleOffIcon} alt="Disabled bundle" />
      <img css={img2CSS(false, isToggleOn)} src={BundleOnGroupIcon} alt="Enabled bundle" />
    </div>
  );
};
