/** @jsxImportSource @emotion/react */
import { useEffect, useMemo, useState, useCallback } from 'react';
import QuoteCard from 'src/components/Life/QuoteCard/QuoteCard';
import { LIFE_QUOTES } from './QuotesWrapper.content';
import noop from 'src/utils/noop';
import { quotesContainerCSS } from './QuotesWrapper.style';

import LifeQuotesLock from 'src/components/Life/LifeQuotesLock/LifeQuotesLock';
import { LifeQuoteSelectData } from 'src/components/Quotes/Quotes.types';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import useQuotes from 'src/api/quotes/useQuotes';
import { useLifeQuotesStateData, useLifeQuotesStatesActions } from 'src/contexts/life-flow-context';
import { LifeQuotesProps } from './QuotesWrapper.types';
import { QuotesResponseStatus } from 'src/interfaces/IQuotes';
import useResponsive from 'src/hooks/useResponsive';
import { LifeQuote, LifeQuotesResponse } from 'src/interfaces/IQuotes';
import quotesService from 'src/api/quotes/quotes.service';
import { useCustomNavigate } from 'src/hooks/useCustomNavigate';
import { useLocation } from 'react-router-dom';
import { useQueryClient } from 'react-query';

// Custom URLS and logic are hardcoded per request CX-3384
const CARRIERS_URL = {
  ladder_life: 'https://www.ladderlife.com/apply/quick-start?utm_source=matic',
  assurity: 'https://quickstart.assurity.com/MaticTermLife',
  sbli_it: 'https://www.helloplum.com/agent/insurance-5253?product=instant&partner=matic',
  sbli: 'https://www.helloplum.com/agent/insurance-5253?product=accelerated&partner=matic',
  legal_general: 'https://www.helloplum.com/agent/insurance-5253?product=banner-term&partner=matic'
};

const QuotesWrapper: React.FC<LifeQuotesProps> = ({ gid, flow }) => {
  const { isDesktop } = useResponsive();
  const [isScreenLocked, setIsScreenLocked] = useState(true);
  const { shouldStartQuoting, currentAnswersState } = useLifeQuotesStateData();
  const navigate = useCustomNavigate();
  const { search } = useLocation();
  const { setShouldStartQuoting, setIsButtonDisabled, setIsBasedBlockVisible } = useLifeQuotesStatesActions();
  const queryClient = useQueryClient();

  const { data, isLoading: isQuotesLoading } = useQuotes<LifeQuotesResponse>(
    flow,
    gid,
    5000,
    noop,
    false,
    !shouldStartQuoting
  );

  const manual_quotes = useMemo(() => (data?.manual_quotes || []) as LifeQuote[], [data?.manual_quotes]);
  const quotes = useMemo(() => (data?.quotes || []) as LifeQuote[], [data?.quotes]);
  const featuredQuote = !!quotes?.length ? quotes[0] : undefined;

  const getQuotesDetailsToReport = useCallback(
    (quote: LifeQuoteSelectData | LifeQuote, ordinal: number, premium: number) => {
      return {
        carrier_key: quote.carrier.key,
        ordinal,
        quote_gid: quote?.gid,
        term: currentAnswersState.term,
        coverage_value: currentAnswersState.coverage_amount,
        premium_value: premium,
        policy_name: quote?.policy_name,
        policy_type: quote?.policy_type
      };
    },
    [currentAnswersState]
  );

  useEffect(() => {
    if (!isQuotesLoading && data?.status === QuotesResponseStatus.Finished) {
      setShouldStartQuoting(false);
      setIsScreenLocked(false);
      setIsBasedBlockVisible(true);
      queryClient.refetchQueries(['disclosures', gid]);

      if (!!featuredQuote) {
        analytics.track(SEGMENT.FEATURED_QUOTE_SHOWED, gid, flow, {
          ...getQuotesDetailsToReport(featuredQuote, 1, featuredQuote.premium.value)
        });
      }
    }
  }, [
    isQuotesLoading,
    setShouldStartQuoting,
    data?.status,
    featuredQuote,
    flow,
    gid,
    setIsBasedBlockVisible,
    queryClient,
    getQuotesDetailsToReport
  ]);

  useEffect(() => {
    if (!!quotes.length || !!manual_quotes.length) {
      setIsButtonDisabled(true);
    }
  }, [manual_quotes.length, quotes.length, setIsButtonDisabled]);

  // Custom URLS and logic are hardcoded per request CX-3384
  const mapCustomURLRedirect = (carrierKey: string) => {
    switch (carrierKey) {
      case 'ladder_life':
        window.open(CARRIERS_URL.ladder_life, '_self');
        break;
      case 'sbli_it':
        window.open(CARRIERS_URL.sbli_it, '_self');
        break;
      case 'sbli':
        window.open(CARRIERS_URL.sbli, '_self');
        break;
      case 'assurity':
        window.open(CARRIERS_URL.assurity, '_self');
        break;
      case 'legal_general':
        window.open(CARRIERS_URL.legal_general, '_self');
        break;
      default:
        navigate({ pathname: `/${flow}/${gid}/confirmation`, search });
    }
  };

  const onQuoteSelect = async (ordinal: number, quoteData: LifeQuoteSelectData) => {
    await quotesService.selectQuote(flow, gid, [quoteData.gid]);

    analytics.track(SEGMENT.QUOTE_SELECTED, gid, flow, {
      ...getQuotesDetailsToReport(quoteData, ordinal, quoteData.premium_value)
    });

    mapCustomURLRedirect(quoteData.carrier.key);
  };

  const onQuoteDetailsReview = async (ordinal: number, quoteData: LifeQuoteSelectData) => {
    analytics.track(SEGMENT.QUOTE_REVIEWED, gid, flow, {
      carrier_key: quoteData.carrier.key,
      ordinal
    });
  };

  if (isScreenLocked || shouldStartQuoting) {
    return (
      <LifeQuotesLock isLoading={shouldStartQuoting}>
        <div css={quotesContainerCSS}>
          {(!isDesktop ? [LIFE_QUOTES[0]] : LIFE_QUOTES).map(q => (
            <QuoteCard quote={q} onQuoteDetailsReview={noop} onQuoteSelect={noop} key={q.gid} />
          ))}
        </div>
      </LifeQuotesLock>
    );
  }

  if (!isQuotesLoading && data?.status === QuotesResponseStatus.Finished && !quotes?.length && !manual_quotes?.length) {
    navigate({ pathname: '/problem' });
  }

  return (
    <div css={quotesContainerCSS}>
      {!!quotes?.length &&
        quotes?.map((q, i) => (
          <QuoteCard
            quote={q}
            isFeatured={i === 0}
            onQuoteSelect={onQuoteSelect}
            onQuoteDetailsReview={onQuoteDetailsReview}
            key={q.gid}
            index={i + 1}
          />
        ))}

      {!!manual_quotes?.length &&
        manual_quotes?.map((q, i) => (
          <QuoteCard
            isManual
            quote={q}
            onQuoteSelect={onQuoteSelect}
            onQuoteDetailsReview={onQuoteDetailsReview}
            key={q.gid}
            index={i + 1}
          />
        ))}
    </div>
  );
};

export default QuotesWrapper;
