/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useRef } from 'react';

import { css } from '@emotion/react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { DefaultLocation, Flows } from 'src/interfaces/IPage';

import Button from 'src/kit/Button/Button';
import { ButtonSize } from 'src/kit/Button/types';
import QuestionForm from 'src/questionsForm/components/QuestionForm/QuestionForm';
import LifeQuestionForm from 'src/questionsForm/components/QuestionForm/LifeQuestionForm';
import Greeting from 'src/questionsForm/components/Greeting/Greeting';
import validateRules from 'src/questionsForm/utils/ruleValidator.utils';
import useFormScroll from 'src/questionsForm/hooks/useFormScroll/useFormScroll';
import { scrollToNode } from 'src/utils/scroll';

import { defaultFormPage, defaultPageGreeting, skip } from './DefaultFormPage.style';
import { formActions } from 'src/theme/shared-styles';
import useResponsive from 'src/hooks/useResponsive';
import { QuestionsFormPageProps } from 'src/questionsForm/types/questionsFormPage.types';
import { getQuestionForms } from 'src/questionsForm/utils/questionsForm.utils';
import { useLifeQuotesStateData, useLifeQuotesStatesActions } from 'src/contexts/life-flow-context';

const DefaultFormPage: React.FC<QuestionsFormPageProps> = ({
  greeting,
  action,
  questions,
  onPageSubmit,
  isCurrent,
  key_name,
  triggerSchemaUpdate,
  uploadFilesWrapperParams,
  isFormSubmitting
}) => {
  const { isDesktop, isMobile, isTablet } = useResponsive();
  const { flow } = useParams() as DefaultLocation;
  const { setIsButtonDisabled: setIsLifeButtonDisabled } = useLifeQuotesStatesActions();
  const { isButtonDisabled: isLifeButtonDisabled } = useLifeQuotesStateData();
  const initialFormStateReported = useRef(false);

  const {
    watch,
    formState: { touchedFields, isDirty },
    setValue,
    clearErrors,
    getValues
  } = useFormContext();

  const {
    person_date_of_birth,
    person_health_status,
    person_sex,
    person_term_length,
    person_tobacco_use,
    person_coverage_limit
  } = getValues();

  //Refactor when validation for life will be ready
  const isInitialValuesPrefilled =
    !!person_coverage_limit &&
    !!person_date_of_birth &&
    !!person_health_status &&
    !!person_sex &&
    !!person_term_length &&
    !!person_tobacco_use;

  useEffect(() => {
    if (initialFormStateReported.current === true) {
      setIsLifeButtonDisabled(!isDirty);
    }

    if (!initialFormStateReported.current && isInitialValuesPrefilled) {
      setIsLifeButtonDisabled(false);
      initialFormStateReported.current = true;
    }
  }, [isDirty, isInitialValuesPrefilled, setIsLifeButtonDisabled]);

  useEffect(() => {
    isCurrent && setTimeout(() => scrollToNode(`section-${key_name}`), 500);
  }, [key_name, isCurrent]);

  const fieldKeys = useMemo(() => questions?.map(q => q.fields.map(f => f.key_name))?.flat() || [], [questions]);
  const values = watch(fieldKeys);
  const allAnswers = watch();
  const answers = Object.fromEntries(fieldKeys.map((_, i) => [fieldKeys[i], values[i]]));
  const isLifeFlowVariant = flow === Flows.Life;

  const visibleForms = getQuestionForms(
    {
      ...allAnswers,
      isMobile,
      isDesktop,
      isTablet
    },
    questions
  );
  const touchedFieldsArray = Object.keys(touchedFields);
  useFormScroll(visibleForms, answers, touchedFieldsArray);

  const isPolicyHolderVisible = visibleForms.map(component => {
    return component.component_key === 'policyholder_questions';
  });

  const shouldShowAction =
    isCurrent &&
    action &&
    validateRules(allAnswers, action.visibility_conditions) &&
    !isPolicyHolderVisible.includes(true);

  const hasSkipBtn = !!action?.skip && validateRules(answers, action.skip.visibility_conditions);
  const isButtonDisabled = action && action.disable_conditions && validateRules(answers, action.disable_conditions);

  const onSkip = async () => {
    const keys = action.skip?.keys || fieldKeys;

    clearErrors(keys);
    keys?.forEach(k => answers.hasOwnProperty(k) && setValue(k, ''));

    await onPageSubmit();
  };

  const onLifeSubmit = () => {
    onPageSubmit();
    setIsLifeButtonDisabled(true);
  };

  return (
    <div css={defaultFormPage(isLifeFlowVariant)} id={`section-${key_name}`} data-testid={`section-${key_name}`}>
      {greeting && Object.keys(greeting).length > 0 && (
        <div css={defaultPageGreeting}>
          <Greeting keyName={key_name} {...greeting} />
        </div>
      )}
      {visibleForms.map(({ isDimmed, ...f }, index) => {
        const isLineHidden = visibleForms.length === index + 1 && isCurrent;
        if (isLifeFlowVariant) {
          return (
            <LifeQuestionForm
              key={f.key_name}
              answers={answers}
              isLineHidden={isLineHidden}
              isDimmed={isDimmed}
              {...f}
              triggerSchemaUpdate={triggerSchemaUpdate}
              uploadFilesWrapperParams={uploadFilesWrapperParams}
              buttonLabel={isLifeButtonDisabled ? 'Update My Quotes' : 'Unlock My Quotes'}
              isButtonLoading={isFormSubmitting}
              onActionClick={onLifeSubmit}
              onFiledChanged={() => setIsLifeButtonDisabled(false)}
              isButtonDisabled={isLifeButtonDisabled}
            />
          );
        } else {
          return (
            <QuestionForm
              key={f.key_name}
              answers={answers}
              isLineHidden={isLineHidden}
              isDimmed={isDimmed}
              {...f}
              triggerSchemaUpdate={triggerSchemaUpdate}
              uploadFilesWrapperParams={uploadFilesWrapperParams}
            />
          );
        }
      })}
      {shouldShowAction && !isLifeFlowVariant && (
        <div css={formActions}>
          <Button
            type="button"
            data-testid="questions-form-action-button"
            css={css`
              width: 100%;
            `}
            size={ButtonSize.Large}
            isLoading={isFormSubmitting}
            onClick={onPageSubmit}
            disabled={isButtonDisabled}
          >
            {action.label}
          </Button>
          {hasSkipBtn && (
            <button type="button" css={skip} onClick={onSkip}>
              Skip for Now
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default DefaultFormPage;
