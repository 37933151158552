/** @jsxImportSource @emotion/react */
import React from 'react';

import Format from 'src/utils/format';
import Button from 'src/kit/Button/Button';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { Flows } from 'src/interfaces/IPage';
import {
  buttonCSS,
  buttonsWrapperCSS,
  deductibleCSS,
  descriptionCSS,
  headerGroupCSS,
  premiumCSS,
  priceWrapperCSS,
  savingCSS,
  savingsWrapperCSS
} from './DefaultModalScreen.style';
import { QuoteSideCardModalScreens } from 'src/components/QuoteSideCardModal/QuoteSideCardModal.types';
import { DefaultModalScreenProps } from './DefaultModalScreen.types';
import userEventDataService, { UserEvents } from 'src/api/userEventsData/userEventsData';

const DefaultModalScreen: React.FC<DefaultModalScreenProps> = ({
  quote,
  actionButtonLabel,
  onQuoteSelected,
  changeScreen,
  gid
}) => {
  const handleScheduleConsultationButtonClick = async () => {
    changeScreen(QuoteSideCardModalScreens.ScheduleCall);

    analytics.track(SEGMENT.CONVERSATION_SCHEDULING_INITIATED, gid, Flows.AllFunnel, {
      page: SEGMENT.PAGES_KEY.QUOTES,
      location: SEGMENT.LOCATIONS.QUOTE_SIDE_MODAL
    });

    try {
      await userEventDataService.callUserEventReport(UserEvents.ScheduleCall, gid);
    } catch {}
  };

  return (
    <div css={headerGroupCSS}>
      <div>
        <div css={savingsWrapperCSS}>
          <div css={priceWrapperCSS}>
            <div css={premiumCSS}>{Format.money(quote.premium.value, 9, '$')}</div>
            {quote.saving_value && <div css={savingCSS}>{Format.money(quote.saving_value, 9, '$')} in savings</div>}
          </div>

          <div css={descriptionCSS}>Est. Annual Premium</div>
          <div css={deductibleCSS}>{Format.money(quote.deductible, 9, '$')} Deductible</div>
        </div>
      </div>
      <div css={buttonsWrapperCSS}>
        <Button onClick={onQuoteSelected} customCSS={buttonCSS}>
          Continue Online
        </Button>

        <Button variant="secondary" onClick={handleScheduleConsultationButtonClick} customCSS={buttonCSS}>
          {actionButtonLabel}
        </Button>
      </div>
    </div>
  );
};

export default DefaultModalScreen;
