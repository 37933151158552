import ScheduleCall from 'src/components/ScheduleCall/ScheduleCall';
import { hrCSS, scheduleCallWidgetCSS, scheduleCallWrapperCSS } from './ScheduleCallModalScreen.styles';

/** @jsxImportSource @emotion/react */
const ScheduleCallModalScreen: React.FC = () => (
  <div>
    <hr css={hrCSS} />
    <ScheduleCall
      customCSS={{ container: scheduleCallWrapperCSS, widget: scheduleCallWidgetCSS }}
      shouldHideFinalHeading
      shouldHideTeam
      shouldHideHeading
    />
  </div>
);

export default ScheduleCallModalScreen;
