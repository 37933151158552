import { Accept, ErrorCode, FileRejection } from 'react-dropzone';
import { ValidateCodes } from './UploadFilesInput.types';

export const rejectCodesToValidateFields: Record<ErrorCode, ValidateCodes> = {
  [ErrorCode.FileInvalidType]: 'accept',
  [ErrorCode.FileTooLarge]: 'maxSize',
  [ErrorCode.TooManyFiles]: 'maxFiles', // can be throw only if other validations passed
  [ErrorCode.FileTooSmall]: 'minSize'
};

export const getFieldsErrors = (rejectedFiles: FileRejection[]): Record<ValidateCodes, boolean> => {
  const tempObj = {
    accept: false,
    maxSize: false,
    maxFiles: false,
    minSize: false
  };

  rejectedFiles.forEach(({ errors }) => {
    errors.forEach(err => {
      tempObj[rejectCodesToValidateFields[err.code as ErrorCode]] = true;
    });
  });

  return tempObj;
};

export const getAcceptValidationRequirements = (requirements: Accept): string =>
  Object.values(requirements).flat().join(', ').replace(/\./g, '').toUpperCase();

export const getValidationRequirementsText = (
  validateKey: ValidateCodes,
  requirements: number | Accept,
  fileLimitReached?: boolean
): string => {
  switch (validateKey) {
    case 'accept':
      return `Supported document formats – ${getAcceptValidationRequirements(requirements as Accept)}`;
    case 'maxSize':
      return `Maximum Size for a File – ${requirements} Mb`;
    case 'maxFiles':
      return fileLimitReached
        ? `You have reached the limit of ${requirements} files for uploading.`
        : `Up to ${requirements} Files`;
    default:
      return '';
  }
};

export const errorMessageByCode: Record<ValidateCodes, string> = {
  accept: 'One or more of your files are in an unsupported format.',
  maxSize: 'One or more of your files exceed the size limit',
  maxFiles: 'You are attempted to upload too many files.',
  minSize: 'One or more of your files are less then min size limit'
};
