/** @jsxImportSource @emotion/react */
import React from 'react';

import { QuoteCardActionsProps } from './QuoteCardActions.types';
import { actionButtonCSS, containerCSS, detailsButtonCSS } from './QuoteCardActions.style';
import noop from 'src/utils/noop';
import Button from 'src/kit/Button/Button';

const QuoteCardActions: React.FC<QuoteCardActionsProps> = ({
  customCSS,
  fullWidth = false,
  actionLabel = 'Choose Quote',
  isDetailsOpen = false,
  showDetailsLabel,
  onShowDetailsClick = noop,
  onChooseClick = noop,
  shouldRenderBndlMsqTest = false
}) => {
  return (
    <div css={[containerCSS, customCSS]}>
      <Button
        data-testid="quote-card-action-button"
        customCSS={actionButtonCSS}
        fullWidth={fullWidth}
        onClick={onChooseClick}
      >
        {actionLabel}
      </Button>
      {!shouldRenderBndlMsqTest && (
        <Button
          variant="text"
          aria-expanded={isDetailsOpen ? 'true' : 'false'}
          customCSS={detailsButtonCSS}
          onClick={onShowDetailsClick}
        >
          {isDetailsOpen ? 'Show Less' : showDetailsLabel ? showDetailsLabel : 'Show Details'}
        </Button>
      )}
    </div>
  );
};

export default QuoteCardActions;
