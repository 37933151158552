import { css } from '@emotion/react';
import { mq } from 'src/theme/variables';

export const scheduleCallWrapperCSS = css`
  padding: 0 !important;
  margin: 0;
`;

export const scheduleCallWidgetCSS = css`
  padding: 0;
  margin: 0;
  height: 880px;
`;

export const hrCSS = css`
  margin: 16px -16px 0;

  ${mq[0]} {
    margin: 34px -16px 0;
  }
`;
