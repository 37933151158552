export const HOME_FORM_PAGE_KEYS = {
  ADDRESS: 'address',
  HOME_DETAILS: 'home_details',
  ABOUT_YOU: 'about_you'
};

export const AUTO_FORM_PAGE_KEYS = {
  START: 'start',
  VEHICLES: 'vehicles',
  DRIVERS: 'drivers',
  BUNDLE_DRIVERS: 'drivers',
  GET_QUOTES: 'get_quotes'
};

export const DRIVERS_FORM_KEYS = {
  QUESTIONS_DRIVER_PERSONAL_INFO: 'questions_driver_personal_info',
  QUESTIONS_DRIVER_EDUCATION_LEVEL: 'questions_driver_education_level'
};

export const QUESTION_KEYS = {
  RELATIONSHIP_TO_POLICYHOLDER: 'driver_relationship_to_policyholder',
  DATE_OF_BIRTH: 'person_date_of_birth',
  PHONE: 'person_phone',
  TERMS_OF_SERVICE: 'disclosure_checkbox',
  WHY_YOU_SHOPPING: 'request_reason',
  MARITAL_STATUS: 'person_marital_status'
};

export const RELATIONSHIP_TO_POLICYHOLDER_OPTIONS = {
  SELF: 'self',
  SPOUSE: 'spouse'
};

export const DISCOUNTS = {
  SECURITY_SYSTEM: 'security_system',
  FIRE_ALARM: 'fire_alarm',
  SPRINKLER_SYSTEM: 'sprinkler_system',
  PROTECTIVE_DEVICES: 'protective_devices'
};

export const KNOCKOUT_QUESTIONS = {
  DANGEROUS_TRAMPOLINE: 'property_has_dangerous_trampoline',
  DANGEROUS_POOL: 'property_has_dangerous_pool',
  DANGEROUS_DOG: 'person_has_dangerous_dog',
  TENANTS: 'property_is_rented',
  SECONDARY_HOME: 'property_is_secondary'
};
