import { QueryObserverResult, useQuery } from 'react-query';
import { QUERY_CACHE_KEYS } from 'src/constants/queryCacheKeys';
import { getApiUrl } from 'src/utils/api.helpers';
import api from 'src/api/api';
import { API_URLS } from 'src/constants/api';
import { ConversationTypes } from 'src/components/QuoteSideCardModal/DefaultModalScreen/DefaultModalScreen.types';

type Source_Dimension_Types = Exclude<ConversationTypes, 'schedule'> | null;

export type Source_Dimension = {
  engagement_experience_id: Source_Dimension_Types;
};

const useSourceDimension = (gid: string): QueryObserverResult<Source_Dimension> => {
  return useQuery([QUERY_CACHE_KEYS.SOURCE_DIMENSION, gid], () => {
    return api.get(getApiUrl(API_URLS.SOURCE_DIMENSIONS, gid), {}, false);
  });
};

export default useSourceDimension;
