/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';

import Container from 'src/kit/Container/Container';
import {
  benefitsTitleCSS,
  benefitsWrapperCSS,
  cardTextCSS,
  cardTitleCSS,
  detailsBlockCSS,
  heroSectionCSS,
  mobileBenefitsWrapperCSS,
  mobileCardTitleCSS,
  offerDetailsCSS,
  savingsCardCSS
} from './SavingsHero.style';
import SavingsOffer from './SavingsOffer/SavingsOffer';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { DefaultLocation, Flows } from 'src/interfaces/IPage';
import { Card } from 'src/shared/components/Card/Card';
import { heroInfoCSS, heroWrapperCSS } from './SavingsOffer/SavingsOffer.style';
import SavingsHead from './SavingsHead/SavingsHead';
import useResponsive from 'src/hooks/useResponsive';
import SavingsCarrier from './SavingsCarrier/SavingsCarrier';
import { useParams } from 'react-router-dom';
import { SavingsHeroProps } from './SavingsHero.types';
import noop from 'src/utils/noop';
import QuoteBenefits from 'src/components/QuoteBenefits/QuoteBenefits';
import { getSavingsBenefitsList } from './SavingHero.utils';
import { SavingsQuote } from 'src/interfaces/IQuotes';
import useConfig from 'src/api/config/useConfig';
import { getServicerNameToDisplay } from 'src/constants/servicers';
import { QuoteBenefitsCustomCSSProps } from 'src/components/QuoteBenefits/QuoteBenefits.types';
import useDisclosures from 'src/api/disclosures/useDisclosures';

const BENEFITS_TITLE = 'Benefits of switching with Matic';

const SavingsHero: React.FC<SavingsHeroProps> = ({
  offer,
  onSubmit = noop,
  isSubmitLoading,
  estimatedSavings,
  predictedPremium,
  predictedPremiumChange,
  preScoreVariation = false,
  shouldHideBenefitsForMobile = false
}) => {
  const [activeQuote] = useState<SavingsQuote>(offer?.quotes[0]);
  const { gid, flow } = useParams() as DefaultLocation;
  const { isMobile } = useResponsive();
  const { data: config } = useConfig(gid);
  const { data: disclosuresData } = useDisclosures(gid);

  const { servicer_key = '', servicer_name } = config || {};
  const servicerName = getServicerNameToDisplay(servicer_key, servicer_name);

  const onFormSubmit = async (phone: string, selectedFromModal: boolean) => {
    if (selectedFromModal) {
      analytics.track(SEGMENT.TERMS_OF_SERVICE_ACCEPTED_FROM_MODAL, gid, flow);
    }

    disclosuresData?.disclosures?.forEach(disclosure => {
      analytics.track(SEGMENT.DISCLOSURE_ACCEPTED, gid, flow, {
        disclosure_gid: disclosure.gid,
        page: SEGMENT.PAGES_KEY.SAVINGS
      });
    });

    analytics.track(SEGMENT.QUOTE_SELECTED, gid, Flows.Savings, {
      quote_type: SEGMENT.SAVINGS_PAGE_DEFAULT_QUOTE_TYPE,
      savings_amount: activeQuote.saving_value,
      carrier_key: activeQuote.carrier.key.toLowerCase(),
      ordinal: 1,
      bundle_status: null,
      quote_gid: activeQuote.gid,
      ...(activeQuote.premium.value && { premium_value: activeQuote.premium.value })
    });

    onSubmit(activeQuote, phone);
  };

  const geQuotesBenefit = (customCSS?: QuoteBenefitsCustomCSSProps) => (
    <QuoteBenefits
      title={BENEFITS_TITLE}
      benefits={getSavingsBenefitsList(servicerName, servicer_key || '', false, preScoreVariation)}
      customCSS={customCSS}
    />
  );

  const shouldRenderRecommendedBanner = isMobile && !preScoreVariation;

  const getTitle = (): string => {
    switch (true) {
      case !!activeQuote?.online_bind_url:
        return 'Proceed Online';
      default:
        return 'Speak With a Matic Advisor';
    }
  };

  const getTitleDescription = (): string => {
    switch (true) {
      case !!activeQuote?.online_bind_url:
        return (
          'We just need a few more details about your property. You may exit ' +
          'at any time or ask for assistance from an Advisor.'
        );
      default:
        return 'A Matic Advisor will contact you within one business day to review your quote.';
    }
  };

  return (
    <section css={heroSectionCSS}>
      <Container>
        <div css={heroWrapperCSS()}>
          <div css={heroInfoCSS()}>
            <SavingsHead
              isPreScoreVariation={preScoreVariation}
              person={offer.person}
              currentPolicy={offer.current_policy}
              activeQuote={activeQuote}
              predictedPremiumChange={predictedPremiumChange}
            />
            {!isMobile &&
              geQuotesBenefit({
                container: benefitsWrapperCSS,
                title: benefitsTitleCSS
              })}
          </div>
          <Card customCSS={savingsCardCSS}>
            {shouldRenderRecommendedBanner && <div css={mobileCardTitleCSS}>Recommended Quote</div>}
            <SavingsCarrier
              bestQuote={activeQuote}
              showQuoteRange
              predictedPremium={predictedPremium}
              isPreScoreVariation={preScoreVariation}
              estimatedSavings={estimatedSavings}
              currentPolicy={offer.current_policy}
            />

            <div>
              <div css={[offerDetailsCSS, detailsBlockCSS]}>
                <h2 css={cardTitleCSS}>{getTitle()}</h2>
                <p css={cardTextCSS}>{getTitleDescription()}</p>
              </div>

              <SavingsOffer
                isImplicitDisclosuresAccepted={!!disclosuresData?.disclosures_accepted}
                address={offer.address}
                person={offer.person}
                bestQuote={activeQuote}
                currentPolicy={offer.current_policy}
                onSubmit={onFormSubmit}
                isSubmitLoading={isSubmitLoading}
              />
            </div>
          </Card>
          {isMobile && !shouldHideBenefitsForMobile && !preScoreVariation && (
            <div css={mobileBenefitsWrapperCSS}>{geQuotesBenefit()}</div>
          )}
        </div>
      </Container>
    </section>
  );
};

export default SavingsHero;
