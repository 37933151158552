import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { Flows } from 'src/interfaces/IPage';

enum LocationStateKeys {
  PREVIOUS_LOCATION = 'prevLocation'
}

interface LocationState {
  prevLocation: string;
}

export const isObjectInstanceOfLocationState = (object: unknown): object is LocationState => {
  return (
    typeof object === 'object' &&
    object !== null &&
    Object.prototype.hasOwnProperty.call(object, LocationStateKeys.PREVIOUS_LOCATION)
  );
};

export const trackIfUserWasRedirectedToHome = (
  state: LocationState,
  pathname: string,
  gid: string,
  flow: Flows
): void => {
  if (pathname.includes(Flows.Home) && ['all-funnel', 'savings'].includes(state.prevLocation)) {
    analytics.track(SEGMENT.TO_HOME_NAVIGATED, gid, flow);
  }
};
