/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import FullPageLoader from 'src/shared/components/FullPageLoader/FullPageLoader';
import { DefaultLocation, Page } from 'src/interfaces/IPage';
import { Answers } from 'src/interfaces/IAnswer';

import { getVisibleFormPart, scrollToError } from 'src/questionsForm/utils/questionsForm.utils';
import FormPage from 'src/questionsForm/components/FormPage/FormPage';
import useQuestions from 'src/api/questions/useQuestions';
import useMutateAnswers from 'src/api/answers/useMutateAnswers';
import { useFormContext } from 'react-hook-form';
import { useLifeQuotesStatesActions } from 'src/contexts/life-flow-context';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import Dialog from 'src/kit/Dialog/Dialog';
import { useDisclosures } from 'src/api/disclosures';
import {
  buttonWrapperCSS,
  contentCSS,
  modalBodyCSS,
  privacyMattersCustomCSS,
  titleCSS
} from 'src/components/SavingsHero/SavingsOffer/SavingsOffer.style';
import Button from 'src/kit/Button/Button';
import CustomTCPAConsent from 'src/components/CustomTCPAConsent/CustomTCPAConsent';
import CloseIcon from 'src/assets/svg/close.svg?react';

const QuestionsForm: React.FC = () => {
  const { flow, gid } = useParams() as DefaultLocation;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisclosureModalOpen, setIsDisclosureModalOpen] = useState(false);

  const { data: questions, isLoading: isQuestionsLoading } = useQuestions(gid);
  const { data: disclosures } = useDisclosures(gid);
  const queryClient = useQueryClient();
  const pages = questions?.schema?.pages || [];
  const { setIsBasedBlockVisible, setShouldStartQuoting, setCurrentAnswersState, setIsButtonDisabled } =
    useLifeQuotesStatesActions();
  const answers = useMemo(() => ({ ...questions?.answers }), [questions]) as Answers;

  const {
    watch,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful }
  } = useFormContext();

  const visiblePages = getVisibleFormPart<Page>(pages, watch());

  const visibleSteps = visiblePages?.map(p => p.key_name) || [];
  const currentStep = questions?.step || '';

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(answers);
    }
  }, [answers, reset, isSubmitSuccessful]);

  const { mutateAsync: mutateAnswers } = useMutateAnswers(flow, gid);

  const onSubmit = async (showDisclosuresModal: boolean) => {
    setIsSubmitting(true);

    await handleSubmit(async (formData: Answers) => {
      const answersData = { ...formData };

      await mutateAnswers({
        ...answersData
      });

      if (showDisclosuresModal) {
        setIsDisclosureModalOpen(true);
        analytics.track(SEGMENT.DISCLOSURES_MODAL_SHOWN, gid, flow);
        return;
      } else {
        handleOnSubmitCallback(formData);
      }
    }, scrollToError)();
    setIsSubmitting(false);
  };

  const handleOnSubmitCallback = (formData: Answers) => {
    queryClient.removeQueries(['quotes', flow, gid]);
    setIsBasedBlockVisible(false);
    setShouldStartQuoting(true);

    const answersToHold = {
      coverage_amount: formData.person_coverage_limit as number,
      term: formData.person_term_length as number
    };

    setCurrentAnswersState(answersToHold);
    analytics.track(SEGMENT.LIFE_SCHEMA.QUOTES_REQUESTED, gid, flow);
  };

  const onPageSubmit = () => async () => {
    analytics.track(SEGMENT.LIFE_SCHEMA.UNLOCK_QUOTES_CLICKED, gid, flow);

    return onSubmit(!disclosures?.disclosures_accepted);
  };

  const onDisclosureFormSubmit = () => {
    disclosures?.disclosures?.forEach(d => {
      analytics.track(SEGMENT.DISCLOSURE_ACCEPTED, gid, flow, {
        disclosure_gid: d.gid,
        page: SEGMENT.PAGES_KEY.LIFE
      });
    });

    setIsDisclosureModalOpen(false);
    onSubmit(false);
  };

  const closePhoneInputModal = () => {
    analytics.track(SEGMENT.PHONE_INPUT_MODAL.CLOSED, gid, flow);

    setIsButtonDisabled(false);
    setIsDisclosureModalOpen(false);
  };

  const title = (
    <div css={titleCSS}>
      <h2>Let`s get started.</h2>
      <CloseIcon onClick={closePhoneInputModal} />
    </div>
  );

  const renderVisiblePage = (p: Page, i: number) => {
    if (i > visibleSteps.indexOf(currentStep)) {
      return null;
    }

    return (
      <FormPage
        key={i}
        isCurrent={currentStep === p.key_name}
        onPageSubmit={onPageSubmit()}
        {...p}
        isFormSubmitting={isSubmitting}
      />
    );
  };

  if (isQuestionsLoading) {
    return <FullPageLoader />;
  }

  return (
    <>
      {visiblePages.map(renderVisiblePage)}

      <Dialog
        isOpen={isDisclosureModalOpen}
        title={title}
        size="small"
        customCSS={{ body: modalBodyCSS, dialogInline: { content: contentCSS(false) } }}
        shouldCloseOnOverlayClick
      >
        <CustomTCPAConsent
          customCSS={privacyMattersCustomCSS(false)}
          location={SEGMENT.LOCATIONS.LIFE_FORM}
          buttonLabel="Continue"
        />

        <div css={buttonWrapperCSS}>
          <Button variant="secondary" onClick={closePhoneInputModal}>
            Cancel
          </Button>
          <Button onClick={onDisclosureFormSubmit} data-testid="disclosure-form-button">
            Continue
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default React.memo(QuestionsForm);
