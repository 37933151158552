import { css } from '@emotion/react';
import { text24 } from 'src/theme/typography';

export const reachYouSoonCSS = css`
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    ${text24};
    margin-top: 13px;
    margin-bottom: 24px;
    text-align: center;
  }

  button {
    width: 100%;
    max-width: 155px;
  }
`;
