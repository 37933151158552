/** @jsxImportSource @emotion/react */

import {
  benefitsTitleCSS,
  benefitsWrapperCSS,
  BBBIconCSS,
  bundleMsgWrapperCSS,
  basedCSS,
  headerCSS,
  containerCSS,
  nameCSS
} from 'src/components/SavingsHero/BundleMsgHero/BundleMsgHero.style';
import { heroInfoCSS } from 'src/components/SavingsHero/SavingsOffer/SavingsOffer.style';
import CarAutoIcon from 'src/assets/svg/car_and_auto.svg';
import useResponsive from 'src/hooks/useResponsive';
import BBBIcon from 'src/components/BBBIcon/BBBIcon';
import { BBBIconVariation } from 'src/components/BBBIcon/BBBIcon.types';
import { QuoteBenefitsCustomCSSProps } from 'src/components/QuoteBenefits/QuoteBenefits.types';
import QuoteBenefits from 'src/components/QuoteBenefits/QuoteBenefits';
import { getPercentRangeValue } from 'src/utils/percentageCalculation';
import { getSavingsBenefitsList } from 'src/components/SavingsHero/SavingHero.utils';

interface BundleHeroMessageProps {
  userName: string;
  isSecondTestVariation: boolean;
  currentPolicyCarrierName: string;
  bestQuoteCarrierName: string;
  currentPolicyPremium: any;
  savingValue: number;
  servicerName: any;
  servicerKey: string;
}

const BundleHeroMessage: React.FC<BundleHeroMessageProps> = ({
  userName,
  isSecondTestVariation,
  currentPolicyCarrierName,
  bestQuoteCarrierName,
  currentPolicyPremium,
  savingValue,
  servicerName,
  servicerKey
}) => {
  const { isMobile } = useResponsive();

  const geQuotesBenefit = (customCSS?: QuoteBenefitsCustomCSSProps) => (
    <QuoteBenefits
      title="Benefits of shopping with Matic."
      benefits={getSavingsBenefitsList(servicerName, servicerKey || '', false, true)}
      customCSS={customCSS}
    />
  );

  return (
    <div css={heroInfoCSS(true)}>
      <div css={containerCSS}>
        <div css={nameCSS}>{userName},</div>
        <div css={headerCSS}>
          <span>Matic found this {bestQuoteCarrierName} home quote that could save you</span> <br />
          <b>{getPercentRangeValue(savingValue, 15, 30)}</b>.
        </div>

        {!isSecondTestVariation && (
          <div css={basedCSS}>
            Based on your current {currentPolicyCarrierName} premium of ${currentPolicyPremium}
          </div>
        )}

        {!isSecondTestVariation && (
          <div css={bundleMsgWrapperCSS}>
            <img src={CarAutoIcon} width={49} alt="Auto And Car" />
            <div>
              <b>Want even more money back in your bank account? </b> {!isMobile && <br />}
              <span>Our agents can look for savings on your auto policy, too.</span>
            </div>
          </div>
        )}
      </div>

      {!isMobile &&
        geQuotesBenefit({
          container: benefitsWrapperCSS,
          title: benefitsTitleCSS
        })}

      {!isMobile && <BBBIcon colorVariation={BBBIconVariation.Blue} customCSS={BBBIconCSS} isSimplifiedVersion />}
    </div>
  );
};

export default BundleHeroMessage;
