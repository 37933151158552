import { SavingsQuote, HomeQuote } from 'src/interfaces/IQuotes';

export interface QuoteSideCardModalProps {
  gid: string;
  quote: HomeQuote;
  ordinal: number;
  shouldRenderRecommended?: boolean;
  isOpen: boolean;
  actionButtonLabel?: string;
  onModalClose: () => void;
  onQuoteSelect: (quote: HomeQuote | SavingsQuote, ordinal: number) => void;
  isDigitalFunnel?: boolean;
}

export enum QuoteSideCardModalScreens {
  ScheduleCall = 'schedule_call',
  ReachYouSoon = 'reach_you_soon',
  Default = 'default'
}
