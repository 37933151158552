import React from 'react';
import { useFormContext } from 'react-hook-form';

import { FormQuestionProps } from 'src/interfaces/IQuestion';

import { QuestionWrapper } from 'src/questionsForm/components/QuestionWrapper/QuestionWrapper';
import QuestionInput from 'src/questionsForm/components/Question/QuestionInput/QuestionInput';
import { getAnnualMileage } from './TypicalMileageQuestion.utils';

const TypicalMileageQuestion: React.FC<FormQuestionProps> = props => {
  const { setValue } = useFormContext();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyNamePrefix = props.key_name.replace('vehicle_daily_mileage', '');

    setValue(`${keyNamePrefix}vehicle_#_annual_mileage`, getAnnualMileage(+e.target.value * 520));
  };

  return <QuestionWrapper {...props} type="number" inputComponent={QuestionInput} onChange={onChange} />;
};

export default TypicalMileageQuestion;
