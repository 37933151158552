/** @jsxImportSource @emotion/react */
import React, { Fragment, useState } from 'react';

import ScheduleCall from 'src/components/ScheduleCall/ScheduleCall';
import {
  wrapperCSS,
  headingCSS,
  carrierImgCSS,
  currentPolicyWrapperCSS,
  headingContainerCSS,
  olbQuotesWrapperCSS,
  headingDescriptionCSS,
  olbQuotesHeadingCSS,
  currentPolicyItemCSS,
  detailsHeaderCSS,
  annualPremiumCSS,
  olbQuotesHeadingDescriptionCSS,
  detailsButtonCSS
} from './ScheduleCallAllFunnel.style';

import { DefaultLocation, Flows } from 'src/interfaces/IPage';
import quotesService from 'src/api/quotes/quotes.service';
import { useParams } from 'react-router-dom';
import QuoteLogo from 'src/components/Quotes/QuoteCard/components/QuoteLogo/QuoteLogo';

import useResponsive from 'src/hooks/useResponsive';
import SIRV from 'src/constants/sirv';
import { quoteCardCSS } from 'src/components/Quotes/StandardHouseQuotes/StandardHouseQuotes.style';
import HomeCard from 'src/components/Quotes/QuoteCard/HomeCard/HomeCard';
import Format from 'src/utils/format';
import Dialog from 'src/kit/Dialog/Dialog';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import isMaticUrl from 'src/utils/isMaticUrl';
import { HomeQuote } from 'src/interfaces/IQuotes';
import QuoteCoverages from 'src/components/Quotes/QuoteCoverages/QuoteCoverages';
import { getHomeQuotesCoveragesGroups, getPremiumLabelByType } from 'src/utils/quotesPage';
import Button from 'src/kit/Button/Button';
import { isWorkingHours } from 'src/utils/date';
import { OLBQuote, ScheduleCallAllFunnelProps } from './ScheduleCallAllFunnel.types';
import userEventDataService, { UserEvents } from 'src/api/userEventsData/userEventsData';

const ScheduleCallAllFunnel: React.FC<ScheduleCallAllFunnelProps> = ({ currentQuote, olbQuotesArray }) => {
  const { flow, gid } = useParams() as DefaultLocation;
  const [selectedQuote, setSelectedQuote] = useState<HomeQuote | null>(null);

  const { isMobile } = useResponsive();

  const trackQuoteReviewed = async (quote: OLBQuote, ordinal: number) => {
    analytics.track(SEGMENT.QUOTE_REVIEWED, gid, flow, {
      carrier_key: quote.carrier.key,
      ordinal
    });

    try {
      await userEventDataService.callUserEventReport(UserEvents.QuoteDetailsClicked, gid);
    } catch {}
  };

  const onOLBQuoteChoose = async (quote: HomeQuote, ordinal: number) => {
    analytics.track(SEGMENT.QUOTE_SELECTED, gid, Flows.AllFunnel, {
      carrier_key: quote.carrier.key,
      ordinal,
      bundle_status: null,
      quote_gid: quote.gid,
      ...(quote.premium.value && { premium_value: quote.premium.value })
    });

    if (!isMaticUrl(quote.online_bind_url!)) {
      throw Error(`Online bind url is not correct. Url: ${quote.online_bind_url}`);
    }
    try {
      await quotesService.selectQuote(flow, gid, [quote.gid]);
    } catch {}
    window.open(quote.online_bind_url!, '_self');
  };

  const showMobileDetails = (quote: OLBQuote) => {
    setSelectedQuote(quote);
  };

  const onShowQuoteDetailClick = (quote: OLBQuote, ordinal: number) => {
    trackQuoteReviewed(quote, ordinal);

    if (isMobile) {
      showMobileDetails(quote);
    }
  };

  const onQuoteSelectFromDetailsPage = () => {
    setSelectedQuote(null);
    onOLBQuoteChoose(selectedQuote!, 1);
  };

  const getCurrentQuote = () => (
    <div css={currentPolicyWrapperCSS}>
      <img css={carrierImgCSS} src={currentQuote.carrier.logo_url} alt="Carrier logo" />
      {currentQuote.deductible && !isMobile && (
        <div css={currentPolicyItemCSS}>
          <span>Deductible</span>
          <span>{Format.money(currentQuote.deductible, 0, '$')}</span>
        </div>
      )}
      {currentQuote.coverages.dwelling && !isMobile && (
        <div css={currentPolicyItemCSS}>
          <span>Dwelling</span>
          <span>{Format.money(currentQuote.coverages.dwelling, 0, '$')}</span>
        </div>
      )}

      <div css={currentPolicyItemCSS}>
        <span>Annual premium</span>
        <span>{Format.money(currentQuote.premium.value, 0, '$')}</span>
      </div>
      <img src={SIRV.SAVE} alt="Save sign" />
    </div>
  );

  const getOLBQuote = (quote: OLBQuote, index: number) => (
    <HomeCard
      key={quote.gid}
      customCSS={quoteCardCSS(index === 0)}
      index={index}
      tags={null}
      quote={quote}
      highlightPremium={false}
      onShowDetailsClicked={() => onShowQuoteDetailClick(quote, index + 1)}
      onQuoteSelect={() => {
        onOLBQuoteChoose(quote, index + 1);
      }}
    />
  );

  return (
    <div css={wrapperCSS}>
      <div css={headingContainerCSS}>
        <div>
          <h1 css={headingCSS}>
            {isWorkingHours()
              ? 'Thanks, a Matic Advisor will reach out shortly'
              : 'Thanks, an Advisor will reach out tomorrow'}
          </h1>
          <p css={headingDescriptionCSS}>
            {isWorkingHours()
              ? `Our next available Advisor will call to review details and finalize your
          ${currentQuote.carrier?.name} policy.`
              : `Our advisors are currently off and will call tomorrow
             to review details and finalize your ${currentQuote.carrier?.name} policy.`}
          </p>
          <p>Prefer to chat at a time that’s convenient for you? Please select a time below.</p>
        </div>
      </div>

      {getCurrentQuote()}

      <ScheduleCall shouldHideHeading />

      <div css={olbQuotesWrapperCSS}>
        {olbQuotesArray?.length && (
          <Fragment>
            <div css={olbQuotesHeadingCSS}>
              <div css={olbQuotesHeadingDescriptionCSS}>Don’t want to wait?</div>
              <p>Buy directly online and we’ll work to finalize the details.</p>
            </div>
            {olbQuotesArray.map((quote, index) => getOLBQuote(quote, index))}
          </Fragment>
        )}
      </div>

      {selectedQuote && (
        <Dialog isOpen onRequestClose={() => setSelectedQuote(null)}>
          <Fragment>
            <div css={detailsHeaderCSS}>
              <QuoteLogo logoUrl={selectedQuote.carrier.logo_url} logoLabel="Carrier logo" />
              <div>
                <div css={annualPremiumCSS}> {Format.money(selectedQuote.premium.value, 0, '$')}</div>
                <div>{getPremiumLabelByType(selectedQuote.premium.type)}</div>
              </div>
            </div>
            <QuoteCoverages groups={getHomeQuotesCoveragesGroups(selectedQuote.coverages)} expandable />
            <Button css={detailsButtonCSS} onClick={onQuoteSelectFromDetailsPage}>
              Choose Quote
            </Button>
          </Fragment>
        </Dialog>
      )}
    </div>
  );
};

export default ScheduleCallAllFunnel;
