enum CoverageHomeKeys {
  Dwelling = 'dwelling',
  OtherStructures = 'other_structures',
  PersonalProperty = 'personal_property',
  MedicalPayments = 'medical_payments',
  PersonalLiability = 'personal_liability',
  LossOfUse = 'loss_of_use'
}

type CoverageInfoHash = {
  [key in CoverageHomeKeys]: string;
};

export const CoverageHomeTitles: CoverageInfoHash = {
  [CoverageHomeKeys.Dwelling]: 'Dwelling',
  [CoverageHomeKeys.OtherStructures]: 'Other structures',
  [CoverageHomeKeys.PersonalProperty]: 'Personal Property',
  [CoverageHomeKeys.MedicalPayments]: 'Medical Payment',
  [CoverageHomeKeys.PersonalLiability]: 'Personal Liability',
  [CoverageHomeKeys.LossOfUse]: 'Loss of use'
};

export const CoverageDescriptions: CoverageInfoHash = {
  [CoverageHomeKeys.Dwelling]:
    'A faulty Christmas light or your attempt at deep frying can cause smoke and fire damage.',
  [CoverageHomeKeys.OtherStructures]:
    'A tree falls on your gazebo during a bad storm. We’ll cover the cost to rebuild.',
  [CoverageHomeKeys.PersonalProperty]:
    'Your stuff got stolen or damaged? This is the maximum amount you’ll be able to claim.',
  [CoverageHomeKeys.MedicalPayments]:
    'We’ll cover medical expenses if a guest or neighbor is injured on your property.',
  [CoverageHomeKeys.PersonalLiability]:
    'We’ll cover the cost if someone else is injured or their property is damaged while at your home',
  [CoverageHomeKeys.LossOfUse]:
    'If your home is under construction from a recent claim, we’ll put you up in a nice hotel or similar rental home.'
};
