/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';
import HouseBgIcon from 'src/assets/svg/house_bg.svg';
import GreyCarBgIcon from 'src/assets/svg/grey_car_bg.svg';
import CarBgIcon from 'src/assets/svg/car_bg.svg';
import MobileHouse from 'src/assets/svg/bundle-house.svg';
import MobileCar from 'src/assets/svg/bundle-car.svg';
import Format from 'src/utils/format';

import {
  bundleWrapperCSS,
  bndlImgCSS,
  imgBgCSS,
  detailsWrapperCSS,
  mobileDetailsWrapperCSS,
  savingsTagCSS,
  savingsValueCSS,
  savingsValueSuffixCSS,
  savingsDescriptionCSS,
  hiddenCSS,
  bundleMobileWrapperCSS,
  mobileCarBndlCSS,
  mobileHomeBndlCSS,
  mobileInnerWrapperCSS,
  headerWrapperCSS,
  MPTagCSS,
  MPWrapperCSS
} from 'src/components/SavingsHero/BundleMsgHero/BundleMsgHero.style';

import useResponsive from 'src/hooks/useResponsive';
import { getPercentRangeValue } from 'src/utils/percentageCalculation';

interface PolicyInfoBundleHeaderProps {
  bestQuoteCarrierName: string;
  isToggleOn: boolean;
  carrierLogoUrl: string;
  savingValue: number;
  isSingleQuoteVariation?: boolean;
  bestQuoteMPPercentage?: number | null;
  bestQuoteMPPremium?: number | null;
  bestQuotePremium: number;
}

export const PolicyInfoBundleHeader: React.FC<PolicyInfoBundleHeaderProps> = ({
  isToggleOn,
  bestQuoteCarrierName,
  carrierLogoUrl,
  savingValue,
  isSingleQuoteVariation,
  bestQuoteMPPercentage,
  bestQuoteMPPremium,
  bestQuotePremium
}) => {
  const { isDesktop } = useResponsive();

  const isMultiPolicyOn = isToggleOn && !!bestQuoteMPPremium;
  const premiumValueToDisplay = isMultiPolicyOn ? bestQuoteMPPremium : bestQuotePremium;

  const getSingleQuotesInfo = () => (
    <div css={detailsWrapperCSS(!!isSingleQuoteVariation, isMultiPolicyOn)}>
      <div css={headerWrapperCSS(isToggleOn, isMultiPolicyOn)}>
        <img src={carrierLogoUrl} alt={bestQuoteCarrierName} width={100} />
        <div css={savingsTagCSS}> Save {getPercentRangeValue(savingValue, 15, 30)} /yr</div>
      </div>
      <div css={MPWrapperCSS(isMultiPolicyOn)}>
        {isMultiPolicyOn && (
          <div css={savingsDescriptionCSS(isToggleOn)}> {Format.money(bestQuotePremium, 0, '$')}/yr</div>
        )}
        <div css={savingsValueCSS(isMultiPolicyOn)}>
          {Format.money(premiumValueToDisplay, 0, '$')}
          <span css={savingsValueSuffixCSS}>/yr</span>
        </div>
        <div css={savingsDescriptionCSS()}>Estimated Annual Premium</div>

        {isMultiPolicyOn && <div css={MPTagCSS}>{bestQuoteMPPercentage}% Bundle discount</div>}
      </div>
    </div>
  );

  return (
    <Fragment>
      {!isDesktop ? (
        <div css={bundleMobileWrapperCSS(!!bestQuoteMPPremium, isToggleOn, isSingleQuoteVariation)}>
          <img src={MobileHouse} css={mobileHomeBndlCSS(isToggleOn, !!bestQuoteMPPremium)} alt="House" />
          <img src={MobileCar} css={mobileCarBndlCSS(isToggleOn, !!bestQuoteMPPremium)} alt="Car" />
          <div css={mobileDetailsWrapperCSS(isSingleQuoteVariation)}>
            <div css={mobileInnerWrapperCSS(!!bestQuoteMPPremium, isToggleOn, isSingleQuoteVariation)}>
              {isSingleQuoteVariation ? (
                getSingleQuotesInfo()
              ) : (
                <>
                  <img src={carrierLogoUrl} alt={bestQuoteCarrierName} width={120} />
                  <div css={savingsValueCSS()}>
                    {Format.money(bestQuotePremium, 0, '$')}
                    <span css={savingsValueSuffixCSS}>/yr</span>
                  </div>
                  <div css={savingsDescriptionCSS()}>Estimated Annual Premium</div>
                  <div css={savingsTagCSS}>{getPercentRangeValue(savingValue, 15, 30)} in annual savings</div>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div css={bundleWrapperCSS}>
          <img src={HouseBgIcon} css={imgBgCSS('left')} alt="House" />
          <img src={GreyCarBgIcon} css={imgBgCSS('right')} alt="Car" />
          <img src={CarBgIcon} css={[hiddenCSS, imgBgCSS('right', isToggleOn)]} alt="Car" />

          <div css={bndlImgCSS}>
            {isSingleQuoteVariation ? (
              getSingleQuotesInfo()
            ) : (
              <div css={detailsWrapperCSS()}>
                <img src={carrierLogoUrl} alt={bestQuoteCarrierName} width={120} />
                <div css={savingsValueCSS()}>
                  {Format.money(bestQuotePremium, 0, '$')}
                  <span css={savingsValueSuffixCSS}>/yr</span>
                </div>
                <div css={savingsDescriptionCSS()}>Estimated Annual Premium</div>
                <div css={savingsTagCSS}>{getPercentRangeValue(savingValue, 15, 30)} in annual savings</div>
              </div>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};
