/** @jsxImportSource @emotion/react */
import { ChangeEvent, FC, useState } from 'react';

import Container from 'src/kit/Container/Container';
import {
  nameCSS,
  headerCSS,
  savingsCSS,
  headerWrapperCSS,
  benefitsWrapperCSS,
  benefitsTitleCSS,
  BBBIconCSS,
  heroSectionCSS,
  mobileBenefitsWrapperCSS,
  heroWrapperCSS,
  modalContentCSS,
  modalBodyCSS,
  privacyMattersCSS,
  buttonWrapperCSS,
  dialogTitleCSS
} from './BundleOnlyHero.style';

import { DefaultLocation, Flows } from 'src/interfaces/IPage';
import useResponsive from 'src/hooks/useResponsive';
import { useParams } from 'react-router-dom';
import QuoteBenefits from 'src/components/QuoteBenefits/QuoteBenefits';
import { getSavingsBenefitsList } from 'src/components/SavingsHero/SavingHero.utils';
import useConfig from 'src/api/config/useConfig';
import { getServicerNameToDisplay } from 'src/constants/servicers';
import BBBIcon from 'src/components/BBBIcon/BBBIcon';
import { BBBIconVariation } from 'src/components/BBBIcon/BBBIcon.types';
import { getPercentRangeValue } from 'src/utils/percentageCalculation';
import { BundleOnlyProps } from './BundleOnlyHero.types';
import BundleOnlyCard from 'src/components/BundleOnlyHero/BundleOnlyCard/BundleOnlyCard';
import BundleNotInterested from 'src/components/BundleOnlyHero/BundleNotInterested/BundleNotInterested';
import Profile from 'src/components/BundleOnlyHero/Profile/Profile';
import { isPhoneValid } from 'src/utils/phone';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import CustomTCPAConsent from 'src/components/CustomTCPAConsent/CustomTCPAConsent';
import Dialog from 'src/kit/Dialog/Dialog';
import { useDisclosures } from 'src/api/disclosures';
import Button from 'src/kit/Button/Button';
import ProfilePhoneNumber from 'src/components/BundleOnlyHero/ProfilePhoneNumber/ProfilePhoneNumber';
import CloseIcon from 'src/assets/svg/close.svg?react';
import { HomeQuote, SavingsQuote } from 'src/interfaces/IQuotes';

const BundleOnlyHero: FC<BundleOnlyProps> = ({ offer, isDisclosuresAccepted, isLoading, onSubmit }) => {
  const { gid, flow } = useParams() as DefaultLocation;
  const { data: config } = useConfig(gid);
  const { data: disclosuresData } = useDisclosures(gid);

  const { isMobile, isDesktop } = useResponsive();

  const [updatedPhone, setUpdatedPhone] = useState('');
  const [hasPhoneError, setHasPhoneError] = useState(false);
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);

  const { servicer_key = '', servicer_name } = config || {};
  const servicerName = getServicerNameToDisplay(servicer_key, servicer_name);

  const savingsQuote = offer.quotes[0];
  const {
    address,
    current_policy: currentPolicy,
    person: { phone: personPhone, first_name: firstName, last_name: lastName }
  } = offer;
  const homeQuotes = offer.quotes
    .slice(1)
    .filter(quote => !quote.bundle_only)
    .slice(0, 3);

  const getBenefitsAndBBB = () => (
    <div>
      <QuoteBenefits
        title="Benefits of shopping with Matic."
        benefits={getSavingsBenefitsList(servicerName, servicer_key || '', false, true)}
        customCSS={{ container: benefitsWrapperCSS, title: benefitsTitleCSS }}
      />
      <BBBIcon colorVariation={BBBIconVariation.Blue} customCSS={BBBIconCSS} isSimplifiedVersion />
    </div>
  );

  const openPhoneInputModal = () => {
    analytics.track(SEGMENT.PHONE_INPUT_MODAL.OPENED, gid, flow);

    setIsPhoneModalOpen(true);
  };

  const closePhoneInputModal = () => {
    analytics.track(SEGMENT.PHONE_INPUT_MODAL.CLOSED, gid, flow);

    setIsPhoneModalOpen(false);
  };

  const validatePhone = () => {
    const isValid = updatedPhone ? isPhoneValid(updatedPhone) : !!personPhone;
    setHasPhoneError(!isValid);
    return isValid;
  };

  const onPhoneUpdate = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setHasPhoneError(false);
    setUpdatedPhone(value);
  };

  const onFormSubmit = (quote: SavingsQuote | HomeQuote, phone: string, fromModal: boolean) => {
    if (fromModal) {
      analytics.track(SEGMENT.TERMS_OF_SERVICE_ACCEPTED_FROM_MODAL, gid, flow);
    }

    disclosuresData?.disclosures?.forEach(disclosure => {
      analytics.track(SEGMENT.DISCLOSURE_ACCEPTED, gid, flow, {
        disclosure_gid: disclosure.gid,
        page: SEGMENT.PAGES_KEY.SAVINGS
      });
    });

    analytics.track(SEGMENT.QUOTE_SELECTED, gid, Flows.Savings, {
      quote_type: SEGMENT.SAVINGS_PAGE_DEFAULT_QUOTE_TYPE,
      savings_amount: quote.saving_value,
      carrier_key: quote.carrier.key.toLowerCase(),
      ordinal: 1,
      bundle_status: null,
      quote_gid: quote.gid,
      ...(quote.premium.value && { premium_value: quote.premium.value })
    });

    onSubmit(quote, phone);
  };

  const handleSubmitButtonClick = (quote: SavingsQuote | HomeQuote, fromModal = false) => {
    if (validatePhone()) {
      const phoneToSubmit = updatedPhone.trim().length === 0 ? personPhone : updatedPhone;
      onFormSubmit(quote, phoneToSubmit, fromModal);
    } else {
      analytics.track(SEGMENT.SAVINGS_INVALID_PHONE_SUBMIT_ATTEMPTED, gid, flow);
    }
  };

  return (
    <section css={heroSectionCSS}>
      <Container>
        <div css={heroWrapperCSS(!isDesktop ? 'unset' : 'baseline')}>
          <div css={headerWrapperCSS}>
            <div css={nameCSS}>{firstName},</div>
            <div css={headerCSS}>
              You can save up to{' '}
              <span css={savingsCSS}> {getPercentRangeValue(savingsQuote.saving_value, 15, 30)}</span> on this home
              policy, as a part of bundle deal with {savingsQuote.carrier.name}.
            </div>
            {!isMobile && getBenefitsAndBBB()}
            {!isMobile && (
              <Profile
                phone={personPhone}
                currentPolicy={currentPolicy}
                firstName={firstName}
                lastName={lastName}
                address={address}
              >
                {personPhone && (
                  <ProfilePhoneNumber
                    phone={personPhone}
                    hasPhoneError={hasPhoneError}
                    onPhoneUpdate={onPhoneUpdate}
                    updatedPhone={updatedPhone}
                    withIcon
                  />
                )}
              </Profile>
            )}
          </div>
          <BundleOnlyCard
            quote={savingsQuote}
            currentPolicy={currentPolicy}
            isLoading={isLoading}
            isDisclosuresAccepted={isDisclosuresAccepted}
            hasPhone={!!personPhone}
            onSubmit={() => (!!personPhone ? handleSubmitButtonClick(savingsQuote, false) : openPhoneInputModal())}
          />
          {isMobile && (
            <div css={mobileBenefitsWrapperCSS}>
              <Profile
                phone={personPhone}
                currentPolicy={currentPolicy}
                firstName={firstName}
                lastName={lastName}
                address={address}
              >
                {personPhone && (
                  <ProfilePhoneNumber
                    phone={personPhone}
                    hasPhoneError={hasPhoneError}
                    onPhoneUpdate={onPhoneUpdate}
                    updatedPhone={updatedPhone}
                    withIcon
                  />
                )}
              </Profile>
            </div>
          )}
          {isMobile && <div css={mobileBenefitsWrapperCSS}>{getBenefitsAndBBB()}</div>}
        </div>
      </Container>

      {homeQuotes.length > 0 && (
        <BundleNotInterested
          homeQuotes={homeQuotes}
          onQuoteSelect={quote =>
            !!personPhone && isDisclosuresAccepted ? handleSubmitButtonClick(quote, false) : openPhoneInputModal()
          }
        />
      )}
      <Dialog
        isOpen={isPhoneModalOpen}
        title={
          <div css={dialogTitleCSS}>
            <h2>Let`s get started.</h2>
            <CloseIcon onClick={closePhoneInputModal} />
          </div>
        }
        size="small"
        customCSS={{ body: modalBodyCSS, dialogInline: { content: modalContentCSS(isMobile) } }}
        shouldCloseOnOverlayClick
      >
        <ProfilePhoneNumber
          phone={personPhone}
          hasPhoneError={hasPhoneError}
          onPhoneUpdate={onPhoneUpdate}
          updatedPhone={updatedPhone}
          isEdit={true}
        />

        {!isDisclosuresAccepted && (
          <CustomTCPAConsent
            buttonLabel="Unlock full bundle quote"
            customCSS={privacyMattersCSS(!!personPhone)}
            location={SEGMENT.LOCATIONS.SF_PRIVACY_MATTERS}
          />
        )}

        <div css={buttonWrapperCSS}>
          <Button variant="secondary" onClick={closePhoneInputModal}>
            Cancel
          </Button>
          <Button onClick={() => handleSubmitButtonClick(savingsQuote, true)}>Continue</Button>
        </div>
      </Dialog>
    </section>
  );
};

export default BundleOnlyHero;
